import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Text,
  Select,
  useColorModeValue,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Input,
  Stack,
  Button,
  FormControl,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { QrCodeIConMobile } from 'components/Icons/Icons';
import { useBookingActions } from 'hooks/useBookingActions';
import moment from 'moment';
import { showTextFloor } from 'variables/columnsData';
import { FaCheck } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { debounce } from 'lodash';

function ScanOPD() {
  const textColor = useColorModeValue('gray.700', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bookingActions = useBookingActions();
  const [theaterPassInfo, setTheaterPassInfo] = useState();
  const [showID, setShowID] = useState();
  const [listShow, setListShow] = useState([]);
  const [confirmCode, setConfirmationCode] = useState('');
  const [totalSeatNotOccupied, setTotalSeatNotOccupied] = useState();
  const [scanning, setScanning] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const scanQRRef = useRef(null);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const successSound = new Audio('sound/success.mp3');
  const failedSound = new Audio('sound/error.mp3');
  const [enableSound, setEnableSound] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const toast = useToast();

  useEffect(async () => {
    const element = scanQRRef.current;
    if (element) {
      element.style.borderWith = 40;
    }
    const res = await bookingActions.getShowsCurrentDate({ status: 1 });

    if (res.data.data.length > 0) {
      setListShow(res.data.data);
      setShowID(res.data.data[0].id);
    }
  }, []);

  useEffect(() => {
    if (confirmCode) {
      debounce((query) => {
        scanQRCode(confirmCode);
      }, 500);
    }
  }, [confirmCode]);

  useEffect(() => {
    let countSeatNotOccupied = 0;
    if (theaterPassInfo && theaterPassInfo.booking_detail) {
      theaterPassInfo.booking_detail.forEach((i) => {
        if (i.status === 0 || i.status === 1) {
          countSeatNotOccupied++;
        }
      });
      setTotalSeatNotOccupied(countSeatNotOccupied);
    }
  }, [theaterPassInfo]);

  function disableCamera() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        // Get all video tracks from the stream
        const videoTracks = stream.getVideoTracks();

        // Disable each video track
        videoTracks.forEach(function (track) {
          track.enabled = false;
        });

        console.log('Camera disabled');
      })
      .catch(function (error) {
        console.log('Error disabling camera:', error);
      });
  }

  const scanQRCode = async (val) => {
    try {
      setIsDisabled(true);
      setTheaterPassInfo(undefined);
      setIsNotFound(false);

      if (!val || val.length === 0) {
        toast({
          description: 'Confirm code cannot be empty.',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        });
        setScanning(true);
        setIsDisabled(false);
        return;
      }

      const res = await bookingActions.scanTheaterPass({
        code: val,
        id: showID,
      });
      if (res && scanning) {
        setIsNotFound(false);
        setTheaterPassInfo(res);
        setScanning(false);
        if (enableSound) {
          if (res.scanned_status === 1) {
            successSound
              .play()
              .catch((error) => console.error('Audio play error:', error));
          } else {
            failedSound
              .play()
              .catch((error) => console.error('Audio play error:', error));
          }
        }
      } else {
        if (!res) {
          setIsNotFound(true);
        }
        if (enableSound) {
          failedSound
            .play()
            .catch((error) => console.error('Audio play error:', error));
        }
      }
      setTimeout(() => {
        setScanning(true);
        setIsDisabled(false);
      }, 1000);
    } catch (error) {
      console.warn(error);
      if (enableSound) {
        failedSound
          .play()
          .catch((error) => console.error('Audio play error:', error));
      }
    }
  };

  const ticketError = (status) => {
    switch (status) {
      case 0:
        return "THIS TICKET HASN'T PRINTED THEATER PASS YET!";
      case 2:
        return 'THIS THEATER PASS WAS ALREADY OCCUPIED!';
      case 3:
        return 'WRONG SHOW!';
    }
  };

  const ENTER_KEY = 13;

  useEffect(() => {
    let barcode = '';

    const handleKeyPress = async (e) => {
      let code = e.keyCode ? e.keyCode : e.which;
      barcode += String.fromCharCode(code);

      if (code === ENTER_KEY) {
        if (buttonRef.current) {
          buttonRef.current.focus();
          setConfirmationCode(barcode.trim());
          setEnableSound(true);
          setTimeout(() => {
            buttonRef.current.click();
          }, 100);
        }
        barcode = '';
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const handleShowTimeChange = (e) => {
    setShowID(parseInt(e.target.value));
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const [hasCameraPermission, setHasCameraPermission] = useState(false);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setHasCameraPermission(true);
        })
        .catch((error) => {
          setHasCameraPermission(false);
          console.log('Lỗi khi yêu cầu quyền camera:', error.message);
        });
    }
  }, []);

  const handleReset = () => {
    setConfirmationCode('');
    setTheaterPassInfo(undefined);
    setIsNotFound(false);
  };

  return (
    <>
      <Card px='0px'>
        <CardHeader px='22px' mb='12px' display={{ sm: 'none', lg: 'block' }}>
          <Flex direction='column'>
            <Text
              float='left'
              color={textColor}
              fontSize='lg'
              fontWeight='bold'
              mb='6px'
            >
              Scan Theater Pass
            </Text>
          </Flex>
        </CardHeader>
        <CardBody pl={{ sm: '0', md: '22px' }}>
          <Flex mb='8px' align='center' ml={{ sm: 2, lg: 0 }}>
            <Flex
              direction={{ sm: 'column', md: 'row' }}
              w='100%'
              alignItems={{ sm: 'flex-start', md: 'center' }}
            >
              <Text me='10px' fontSize={{ sm: '12px', md: '14px' }}>
                Show Time
              </Text>
              <Select
                variant='main'
                fontSize='xs'
                size='sm'
                name='payment_status'
                maxW='300px'
                onChange={handleShowTimeChange}
              >
                {listShow.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.time.slice(0, -3)} - {item.show_schedule.show.name} -{' '}
                    {item.show_schedule.venue.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          {isMobile && (
            <Flex
              m={'auto'}
              mb={2}
              display={{ sm: 'flex', lg: 'none' }}
              maxW={'215px'}
              maxH={'200px'}
              className='scanQr'
            >
              {hasCameraPermission && (
                <QrScanner
                  className='tests'
                  onDecode={(result) => scanQRCode(result)}
                  onError={(error) => alert(error?.message)}
                />
              )}
            </Flex>
          )}
          <Stack
            maxW={{ lg: '70%', sm: '100%' }}
            direction={{ sm: 'column', md: 'row' }}
            spacing='30px'
            mb={2}
          >
            <FormControl display='flex' alignItems='center' pl={2} pt={4}>
              {!isMobile && <Text me='10px'>Confirmation Code</Text>}
              <Flex align='center'>
                <Input
                  fontSize='sm'
                  ms='4px'
                  type='text'
                  placeholder='Confirmation Code'
                  size='sm'
                  value={confirmCode}
                  onChange={(e) => {
                    setConfirmationCode(e.target.value);
                  }}
                  maxW='200px'
                  ref={inputRef}
                />
                {!isMobile && (
                  <QrCodeIConMobile
                    style={{
                      cursor: 'pointer',
                    }}
                    ml={{ sm: '6px', lg: '24px' }}
                    w={{ sm: '24px', lg: '48px' }}
                    h={{ sm: '24px', lg: '48px' }}
                    onClick={() => {
                      onOpen();
                    }}
                  />
                )}
                <Button
                  size='sm'
                  colorScheme='blue'
                  ml={{ lg: 4, sm: 1 }}
                  fontSize='base'
                  px={7}
                  py={2}
                  disabled={isDisabled}
                  onClick={() => scanQRCode(confirmCode)}
                  ref={buttonRef}
                >
                  Scan
                </Button>
                <Button
                  size='sm'
                  colorScheme='gray'
                  ml={{ lg: 4, sm: 1 }}
                  fontSize='base'
                  px={7}
                  py={2}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Flex>
            </FormControl>
          </Stack>

          {theaterPassInfo && (
            <Flex
              direction='column'
              position='relative'
              borderRadius={'15px'}
              p={2}
              background={
                theaterPassInfo.scanned_status === 1
                  ? 'rgba(84, 220, 82, 0.50)'
                  : 'rgba(234, 115, 89, 0.50)'
              }
              maxW={{ lg: '70%' }}
              minH={'115px'}
            >
              {theaterPassInfo.scanned_status === 1 ? (
                <>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Confirmation Code:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo.confirmation_code}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Scanned at:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo.scanned_status === 1 &&
                        moment(theaterPassInfo?.scanned_at).format(
                          'DD/MM/YYYY hh:mm:ss'
                        )}
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Confirmation Code:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo.confirmation_code}
                    </Text>
                  </Flex>
                  <Flex
                    fontSize={{ sm: '18px', lg: '30px' }}
                    fontWeight='bold'
                    maxW='70%'
                  >
                    {ticketError(theaterPassInfo.scanned_status)}
                  </Flex>
                </>
              )}
              {theaterPassInfo.scanned_status === 1 ? (
                <FaCheck className='check-icon' />
              ) : (
                <AiOutlineClose className='close-icon' />
              )}
              {theaterPassInfo.scanned_status !== 3 && (
                <>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Show Name:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.show?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Show Date:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {moment(
                        theaterPassInfo?.show_schedule_detail?.date
                      ).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Zone:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.zone?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Venue:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.venue?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Show Time:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.show_schedule_detail.time}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Floor:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {showTextFloor(theaterPassInfo?.floor)}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Seat No.:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.seat_no}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2} mb={{ lg: 2, sm: 1 }}>
                    <Text fontSize='16px' color='#2D3748'>
                      Booking No.:
                    </Text>
                    <Text fontWeight='bold' fontSize='18px'>
                      {theaterPassInfo?.code}
                    </Text>
                  </Flex>
                  {theaterPassInfo.scanned_status == 1 && (
                    <Flex
                      fontSize={{ md: '24px', sm: '16px' }}
                      fontWeight='bold'
                      flexWrap='wrap'
                    >
                      <Text wordBreak='break-word'>
                        {totalSeatNotOccupied}/
                        {theaterPassInfo?.booking_detail?.length} seats haven't
                        been occupied yet {''}
                        {theaterPassInfo.booking_detail.map((i, index) => (
                          <span
                            style={{
                              color: i.status === 2 ? 'green' : 'red',
                              marginRight: '4px',
                            }}
                            key={index}
                          >
                            {i.seat}
                          </span>
                        ))}
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          )}
          {isNotFound && (
            <Flex
              direction='column'
              position='relative'
              borderRadius={'15px'}
              p={2}
              background={'rgba(234, 115, 89, 0.50)'}
              maxW={{ lg: '70%' }}
            >
              {isMobile ? (
                <AiOutlineClose
                  className='close-icon'
                  style={{ width: 25, height: 25, right: 5 }}
                />
              ) : (
                <AiOutlineClose
                  className='close-icon'
                  style={{ width: 40, height: 40 }}
                />
              )}
              <Flex
                fontSize={{ md: '24px', sm: '16px' }}
                fontWeight='bold'
                align='center'
                verticalAlign='center'
              >
                <Text wordBreak='break-word'>
                  Theater pass is invalid or does not exist.
                </Text>
              </Flex>
            </Flex>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          disableCamera();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scan QR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QrScanner
              onDecode={(result) => scanQRCode(result)}
              onError={(error) => alert(error?.message)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ScanOPD;
