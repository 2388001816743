import {
  Button,
  Flex,
  Stack,
  useColorModeValue,
  Text,
  Grid,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  useDisclosure,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { HSeparator } from 'components/Separator/Separator';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { showTextFloor } from 'variables/columnsData';
import { useBookingActions } from 'hooks/useBookingActions';
import { useParams } from 'react-router-dom';
import { columnsDataBookingDetailCode } from 'variables/columnsData';
import SearchTable2 from 'components/Tables/SearchTable2';
import { IoMdPrint } from 'react-icons/io';

export default function BookingTheaterPass() {
  const { booking_id } = useParams();
  const [bookingDetail, setBookingDetail] = useState();
  const [listCodes, setListCodes] = useState();
  const [totalRows, setTotalRows] = useState();
  const [actionReloadPage, setActionReloadPage] = useState();
  const bookingActions = useBookingActions();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  //print
  const [bookingDetailCodeIDs, setBookingDetailCodeIDs] = useState([]);
  const [clearAllCheckBox, setClearAllCheckBox] = useState();
  const [isFetching, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(async () => {
    const {
      data: { data: bookingDetail },
    } = await bookingActions.getBookingDetail(booking_id);
    setBookingDetail(bookingDetail);
    setListCodes(bookingDetail.booking_detail);
    var count = bookingDetail.booking_detail.length;
    setTotalRows(count);
  }, [actionReloadPage, booking_id]);

  const recallApi = async () => {
    setIsLoading(true);
    const {
      data: { data: bookingDetail },
    } = await bookingActions.getBookingDetail(booking_id);
    setBookingDetail(bookingDetail);
    setListCodes(bookingDetail.booking_detail);
    var count = bookingDetail.booking_detail.length;
    setTotalRows(count);
    setIsLoading(false);
  };

  //print all
  const getValueChecked = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setBookingDetailCodeIDs(checkedIDs);
    }
  };
  const showPDFPrintCode = async () => {
    const ids = bookingDetailCodeIDs.map((i) => parseInt(i)).join(',');
    const response = await bookingActions.getBookingDetailCodePDF(
      booking_id,
      ids
    );
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
    confirmPrint();
  };
  const showAllPDFPrintCode = async () => {
    const response = await bookingActions.getBookingDetailCodePDF(
      booking_id,
      ''
    );
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
    confirmPrint();
  };

  const showPDFPrintCodeV2 = async () => {
    const ids = bookingDetailCodeIDs.map((i) => parseInt(i)).join(',');
    const response = await bookingActions.getBookingDetailCodePDFV2(
      booking_id,
      ids
    );
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
    confirmPrint();
  };
  const showAllPDFPrintCodeV2 = async () => {
    const response = await bookingActions.getBookingDetailCodePDFV2(
      booking_id,
      ''
    );
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
    confirmPrint();
  };
  const confirmPrint = async () => {
    try {
      const ids = bookingDetailCodeIDs.map((i) => parseInt(i)).join(',');
      const res = await bookingActions.confirmPrintBookingDetailCode(
        booking_id,
        ids
      );
      if (res.status === 200) {
        setIsLoading(true);
        toast({
          description: `Print successful`,
          status: 'success',
          position: 'top-right',
          duration: 2000,
        });
        setActionReloadPage(Math.random());
        setIsLoading(false);
        onClose();
        setBookingDetailCodeIDs([]);
        setClearAllCheckBox(Math.random());
      }
    } catch (error) {
      toast({
        description: `${error?.response?.data?.meta?.message}`,
        status: 'error',
        position: 'top-right',
        duration: 2000,
      });
    }
  };
  return (
    <Card>
      <CardHeader px='22px'></CardHeader>
      <CardBody>
        <Flex justifyContent='space-between' gap='15px' mb={2}>
          <Flex direction='column' w='100%'>
            <Flex>
              <Grid templateColumns='repeat(3, auto)' gap='16px'>
                <Flex direction='column'>
                  <Text fontSize='18px' fontWeight='bold' mb='10px'>
                    Print Theater Pass
                  </Text>
                </Flex>
              </Grid>
            </Flex>

            <Flex>
              <Grid
                templateColumns={{
                  sm: '1fr',
                  lg: 'repeat(2, auto)',
                }}
              >
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Booking No.:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {bookingDetail?.code}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Create At:
                  </Text>
                  <Text fontWeight='bold' fontSize='16px'>
                    {moment(bookingDetail?.created_at).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Show Name:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {
                      bookingDetail?.show_schedule_detail?.show_schedule?.show
                        .name
                    }
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Venue:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {
                      bookingDetail?.show_schedule_detail?.show_schedule?.venue
                        .name
                    }
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Show Date:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {moment(bookingDetail?.show_schedule_detail?.date).format(
                      'DD/MM/YYYY'
                    )}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='16px' color='#718096'>
                    Show Time:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {bookingDetail?.show_schedule_detail?.time.slice(0, -3)}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Address:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {
                      bookingDetail?.show_schedule_detail?.show_schedule?.venue
                        .address
                    }
                  </Text>
                </Flex>
                <Flex></Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Adult:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {bookingDetail?.adult}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='16px' color='#718096'>
                    Child (5 - 12 ages):
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {bookingDetail?.child}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='16px' color='#718096'>
                    Zone:
                  </Text>
                  <Text fontWeight='bold' fontSize='15px'>
                    {bookingDetail?.zone?.name}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='16px' color='#718096'>
                    Floor:
                  </Text>
                  <Text fontWeight='bold' fontSize='16px'>
                    {showTextFloor(bookingDetail?.floor)}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Seat No.:
                  </Text>
                  <Text
                    cursor={'pointer'}
                    fontWeight='bold'
                    ml={1}
                    fontSize='18px'
                    color='var(--chakra-colors-blue-500)'
                  >
                    {bookingDetail?.booking_detail
                      ?.map((i) => i.seat)
                      .join(', ') ?? ''}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Channel:
                  </Text>

                  <Text
                    maxW='190px'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    fontWeight='bold'
                    ml={1}
                    fontSize='18px'
                  >
                    {bookingDetail?.unit?.channel.name}
                  </Text>
                </Flex>
                <Flex align='center' gap={2} mb={2}>
                  <Text fontSize='15px' color='#718096'>
                    Unit:
                  </Text>

                  <Text
                    maxW='190px'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    fontWeight='bold'
                    ml={1}
                    fontSize='15px'
                  >
                    {bookingDetail?.unit?.name}
                  </Text>
                </Flex>
              </Grid>
            </Flex>
            <HSeparator my={4} />
            <Stack direction='row' spacing={4}>
              {bookingDetailCodeIDs.length > 0 && (
                <Stack direction='row' spacing={4}>
                  <Button
                    size='sm'
                    onClick={() => {
                      showPDFPrintCode();
                    }}
                    variant='primary'
                    minW={'100px'}
                  >
                    Print
                  </Button>
                </Stack>
              )}
              <Button
                size='sm'
                onClick={() => {
                  showAllPDFPrintCode();
                }}
                variant='primary'
                minW={'100px'}
                leftIcon={<IoMdPrint />}
              >
                Print All
              </Button>
            </Stack>
            <Stack direction='row' spacing={4} mt={2}>
              {bookingDetailCodeIDs.length > 0 && (
                <Stack direction='row' spacing={4}>
                  <Button
                    size='sm'
                    onClick={() => {
                      showPDFPrintCodeV2();
                    }}
                    variant='primary'
                    minW={'100px'}
                  >
                    Print - V2
                  </Button>
                </Stack>
              )}
              <Button
                size='sm'
                onClick={() => {
                  showAllPDFPrintCodeV2();
                }}
                variant='primary'
                minW={'100px'}
                leftIcon={<IoMdPrint />}
              >
                Print All - V2
              </Button>
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
              }}
              size='6xl'
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Print Review</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  {pdfUrl && (
                    <iframe
                      title='PDF Viewer'
                      src={pdfUrl}
                      width='100%'
                      height='600px'
                    />
                  )}
                </ModalBody>
                <ModalFooter pt='0'>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
        {listCodes && (
          <SearchTable2
            tableData={listCodes}
            columnsData={columnsDataBookingDetailCode}
            totalRow={totalRows}
            enableRowSelection
            isFetching={isFetching}
            loadData={() => recallApi()}
            updateCheckbox={(val) => getValueChecked(val)}
            enableRowActions={false}
          />
        )}
      </CardBody>
    </Card>
  );
}
