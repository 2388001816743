import {
  Select,
  Button,
  Box,
  Flex,
  Grid,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import EventCalendar from 'components/Calendars/EventCalendar';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { getFirstAndLastDate } from 'helpers/date';
import { useBookingActions } from 'hooks/useBookingActions';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  showsScheduleAtom,
  selectedShowAtom,
  listShowAtom,
  venueListAtom,
} from 'state/show';
import { useHistory } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';

function Calendar() {
  const { register, handleSubmit, setError, watch } = useForm();
  const showsSchedule = useRecoilValue(showsScheduleAtom);
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const [selectedShow, setSelectedShow] = useRecoilState(selectedShowAtom);
  const bookingActions = useBookingActions();
  const currentYear = new Date().getFullYear();
  const nextTwoYears = [currentYear + 1, currentYear + 2];
  const yearOption = [currentYear, ...nextTwoYears];
  const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [yearUpdate, setYearUpdate] = useState(currentYear);
  const [monthUpdate, setMonthUpdate] = useState(new Date().getMonth());

  useEffect(() => {
    bookingActions.getListShow({ status: 1 }, 0, '');
    bookingActions.getListVenue({ status: 1 }, 0, '');
  }, []);
  const history = useHistory();
  const getValueMonthChange = useCallback(
    (val, val1) => {
      if (val1) {
        const { firstDate, lastDate } = getFirstAndLastDate(val1, val);

        try {
          bookingActions.getShowsSchedule({
            show_id: watch('show_id'),
            venue_id: watch('venue_id'),
            status: watch('status'),
            from_date: firstDate,
            to_date: lastDate,
          });
        } catch (error) {
          setError('apiError', { message: 'Something wrong' });
        }
      }
    },
    [bookingActions, setError, watch]
  );
  const showStatus = ['All', 'Active', 'InActive', 'Cancel'];
  function onSubmit({ show_id, venue_id, status, year, month }) {
    const { firstDate, lastDate } = getFirstAndLastDate(year, month - 1);
    setMonthUpdate(month - 1);
    setYearUpdate(year);
    try {
      bookingActions.getShowsSchedule({
        show_id,
        venue_id,
        status,
        from_date: firstDate,
        to_date: lastDate,
      });
    } catch (error) {
      setError('apiError', { message: 'Something wrong' });
    }
  }
  function selectShow(val, status) {
    if (status === 3) return;
    bookingActions.selectShow({ id: parseInt(val) }).then((res) => {
      if (res.status === 200) {
        setSelectedShow(res.data.data);
        history.push('/admin/booking/create/information');
      }
    });
  }
  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={{ sm: 'row', base: 'column' }}
          alignItems={{ xl: 'center', base: 'flex-end' }}
          mb={4}
          flexWrap={{ sm: 'wrap', base: 'nowrap' }}
          sx={{
            marginLeft: '-8px',
            marginRight: '-8px',

            '& > *': {
              marginBottom: '8px !important',
              marginRight: '8px !important',
              marginLeft: '8px !important',

              '@media (max-width: 479px)': {
                width: '100%',
              },
            },
          }}
        >
          <Box
            flexDirection={{ xl: 'row', base: 'column' }}
            alignItems={{ xl: 'center', base: 'start' }}
            display='flex'
          >
            <FormLabel
              fontWeight='semibold'
              fontSize='xs'
              mb={0}
              mr={{ xl: '4px', base: '0' }}
            >
              Show
            </FormLabel>
            <Select
              ms={{ xl: '4px', base: '0' }}
              type='text'
              size='sm'
              name='show_id'
              {...register('show_id')}
              width={{ xl: '200px', base: '100%' }}
            >
              <option value=''>All Show</option>
              {listShow.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box
            flexDirection={{ xl: 'row', base: 'column' }}
            alignItems={{ xl: 'center', base: 'start' }}
            display='flex'
          >
            <FormLabel
              fontWeight='semibold'
              fontSize='xs'
              mb={0}
              mr={{ xl: '4px', base: '0  ' }}
            >
              Venue
            </FormLabel>
            <Select
              ms={{ xl: '4px', base: '0' }}
              type='text'
              size='sm'
              name='venue_id'
              {...register('venue_id')}
              width={{ xl: '200px', base: '100%' }}
            >
              <option value=''>All Venue</option>
              {venueList.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </Select>
          </Box>

          <Box
            flexDirection={{ xl: 'row', base: 'column' }}
            alignItems={{ xl: 'center', base: 'start' }}
            display='flex'
          >
            <FormLabel
              fontWeight='semibold'
              fontSize='xs'
              mb={0}
              mr={{ xl: '4px', base: '0  ' }}
            >
              Show Status
            </FormLabel>
            <Select
              ms={{ xl: '4px', base: '0' }}
              type='text'
              defaultValue='Active'
              size='sm'
              name='status'
              {...register('status')}
              width={{ xl: '100px', base: '100%' }}
            >
              {showStatus.map((i, index) => (
                <option key={index} value={i}>
                  {i}
                </option>
              ))}
            </Select>
          </Box>
          <Box
            flexDirection={{ xl: 'row', base: 'column' }}
            alignItems={{ xl: 'center', base: 'start' }}
            display='flex'
          >
            <FormLabel
              fontWeight='semibold'
              fontSize='xs'
              mb={0}
              mr={{ xl: '4px', base: '0  ' }}
            >
              Year
            </FormLabel>
            <Select
              ms={{ xl: '4px', base: '0' }}
              type='text'
              size='sm'
              name='year'
              {...register('year')}
              width={{ xl: '100px', base: '100%' }}
            >
              {yearOption.map((i, index) => (
                <option key={index} value={i}>
                  {i}
                </option>
              ))}
            </Select>
          </Box>

          <Box
            flexDirection={{ xl: 'row', base: 'column' }}
            alignItems={{ xl: 'center', base: 'start' }}
            display='flex'
          >
            <FormLabel
              fontWeight='semibold'
              fontSize='xs'
              mb={0}
              mr={{ xl: '4px', base: '0  ' }}
            >
              Month
            </FormLabel>
            <Select
              ms={{ xl: '4px', base: '0' }}
              type='text'
              size='sm'
              name='month'
              {...register('month')}
              width={{ xl: '100px', base: '100%' }}
              defaultValue={new Date().getMonth() + 1}
            >
              {listMonth.map((i, index) => (
                <option key={index} value={i}>
                  {i}
                </option>
              ))}
            </Select>
          </Box>
          <Button
            leftIcon={<BsSearch />}
            variant='primary'
            w='88px'
            h='33px'
            type='submit'
            mt={{ xl: 0, base: 4 }}
          >
            Search
          </Button>
        </Stack>
      </form>
      <CardBody position='relative' display='block' height='100%' bg='#ebebeb'>
        <EventCalendar
          calendarData={showsSchedule}
          yearUpdate={yearUpdate}
          getValueMonthChange={(val, val1) => getValueMonthChange(val, val1)}
          monthUpdate={monthUpdate}
          selectShow={(val, status) => selectShow(val, status)}
        />
      </CardBody>
    </Card>
  );
}

export default Calendar;
