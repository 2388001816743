import {
  Button,
  Flex,
  Image,
  Stack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import routesArray from "routes.js";
import { SearchIcon } from "@chakra-ui/icons";
import { useUserActions } from "hooks/useUserActions";
import { authAtom } from "state/auth";
import { useRecoilValue } from "recoil";
import logo from "assets/img/logo.png";

export default function HeaderLinks(props) {
  const routes = routesArray();
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    onOpenSearch,
    ...rest
  } = props;

  const { colorMode } = useColorMode();
  const userAction = useUserActions();
  const user = useRecoilValue(authAtom);
  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("gray.700", "gray.200");
  if (secondary) {
    navbarIcon = "gray.700";
  }

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      gap={{md: "10px", base: "2px"}}
    >
      <Button
        ms="0px"
        px="0px"
        color={navbarIcon}
        variant="no-effects"
        rightIcon={
          document.documentElement.dir ? (
            ""
          ) : (
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          )
        }
        leftIcon={
          document.documentElement.dir ? (
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          ) : (
            ""
          )
        }
      >
        {user?.email ? user.email.split('@')[0] : ''}
      </Button>
      <SidebarResponsive
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            <Image src={logo} alt="logo" width={100} height={70} />
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      <SearchIcon
        cursor="pointer"
        onClick={onOpenSearch}
        color={navbarIcon}
        ml={{ lg: 2, sm: 0 }}
        mr={{ lg: 2, sm: 1 }}
        display={{ md: "none", base: "block" }}
      />
      <Button size="sm" onClick={userAction.logout}>
        Logout
      </Button>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
