import React, { useState } from "react";
import {
  Skeleton,
  Flex,
  Text,
  useColorModeValue,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HSeparator } from "components/Separator/Separator";
import { typeRatePlan } from "variables/columnsData";

export default function RatePlanCard({
  item,
  onSelect,
  bookingInfo,
  selectedRatePlan,
}) {
  const textColor = useColorModeValue("gray.700", "white");
  const isSelected = selectedRatePlan.includes(item.rateplan_configs[0].id);
  return (
    <Card
      minH="100%"
      alignSelf="flex-start"
      border={isSelected && "2px solid var(--chakra-colors-blue-500)"}
      onClick={() => onSelect(item.rateplan_configs[0].id)}
      style={{ pointerEvents: `${isSelected ? "none" : "auto"}` }}
    >
      <CardHeader>
        <Flex justify="space-between" w="100%">
          {isSelected && (
            <CheckCircleIcon
              boxSize={5}
              color="var(--chakra-colors-blue-500)"
              position="absolute"
              top="8px"
              right="8px"
            />
          )}

          <Text fontSize="md" color={textColor} fontWeight="bold" mb="8px">
            {item.name}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        {bookingInfo && (
          <Flex direction="column">
            {typeRatePlan(item.type)}
            <UnorderedList pl={3}>
              {item.type === 6 ? (
                <ListItem>
                  Price:{" "}
                  {item.rateplan_configs[0].adult_amount.toLocaleString(
                    "en-US"
                  )}{" "}
                  VND
                </ListItem>
              ) : (
                <ListItem>
                  Adult Price:{" "}
                  {item.rateplan_configs[0].adult_amount.toLocaleString(
                    "en-US"
                  )}{" "}
                  VND
                  {item.rateplan_configs[0].extra_adult_amount > 0 && (
                    <span>
                      - Extra Adult:{" "}
                      {item.rateplan_configs[0].extra_adult_amount.toLocaleString(
                        "en-US"
                      )}{" "}
                      VND
                    </span>
                  )}
                </ListItem>
              )}
              {bookingInfo.child > 0 && (
                <ListItem>
                  Child Price:{" "}
                  {item.rateplan_configs[0].child_amount.toLocaleString(
                    "en-US"
                  )}{" "}
                  VND
                  {item.rateplan_configs[0].extra_child_amount > 0 && (
                    <>
                      - Extra Child:{" "}
                      {item.rateplan_configs[0].extra_child_amount.toLocaleString(
                        "en-US"
                      )}{" "}
                      VND
                    </>
                  )}
                </ListItem>
              )}
            </UnorderedList>
            <HSeparator my={2} />
            <Text fontSize="md">
              <span style={{ fontWeight: "bold" }}>Total Price: </span>
              <span style={{ fontWeight: "bold" }}>
                {item?.total_price && item?.total_price.toLocaleString("en-US")}{" "}
                VND /{" "}
              </span>
              {item?.total_seat} seats
            </Text>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
}
