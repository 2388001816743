import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Switch,
  Image,
  Checkbox,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Box,
  RadioGroup,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
// Assets
import { BsCircleFill, BsSearch } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Select } from "chakra-react-select";
import { listShowAtom, venueListAtom } from "state/show";
import { useBookingActions } from "hooks/useBookingActions";
import { useRecoilState, useRecoilValue } from "recoil";
import Editor from "components/Editor/Editor";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import { typeRatePlan } from "variables/columnsData";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import { zoneListAtom } from "state/show";
import ZoneItem from "components/ZoneItem/ZoneItem";
import { showsScheduleAtom } from "state/show";
import moment from "moment";
import { listChannelAtom } from "state/show";
import { listUnitAtom } from "state/show";
import {
  columnsDataRatePlanUnits,
  columnsViewDataRatePlanUnits,
} from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { ratePlansUnitAtom } from "state/ratePlans";
import { isEmpty } from "lodash";
import { RxReset } from "react-icons/rx";
import { NumericFormat } from "react-number-format";
import { showTextFloor } from "variables/columnsData";
import { MdDelete } from "react-icons/md";
import SearchTable2 from "components/Tables/SearchTable2";

function NewRatePlan({
  onCloseModal,
  ratePlanItem,
  updateNewRatePlan,
  isCopy,
}) {
  const typeOptions = [
    { id: 1, name: "FAMILY PACKAGE" },
    { id: 2, name: "COMBO" },
    { id: 3, name: "EARLY BIRD" },
    { id: 4, name: "STANDARD" },
    { id: 5, name: "CAMPAIGN" },
    { id: 6, name: "PROMOTION" },
  ];

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
      maxW: "250px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 30,
    }),
  };
  const chakraStyles2 = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 30,
    }),
  };

  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "black");
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const zoneList = useRecoilValue(zoneListAtom);
  const showsSchedule = useRecoilValue(showsScheduleAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const [listUnit, setListUnit] = useRecoilState(listUnitAtom);
  const totalRows = useRecoilValue(totalRow);
  const ratePlanUnits = useRecoilValue(ratePlansUnitAtom);
  const bookingActions = useBookingActions();
  const ratePlanActions = useRatePlanActions();
  const [closeModal, setCloseModal] = useState(false);
  const [listCheckedChoose, setListCheckedChoose] = useState([]);
  const [listUnitChoosed, setListUnitChoosed] = useState([]);
  const [clearAllCheckBox, setClearAllCheckBox] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [listSeatingPlanZoneA, setSeatingPlanZoneA] = useState([]);
  const [listSeatingPlanZoneO, setSeatingPlanZoneO] = useState([]);
  const [listSeatingPlanZoneW, setSeatingPlanZoneW] = useState([]);
  const [selectedZoneA, setSelectedZoneA] = useState([]);
  const [selectedZoneO, setSelectedZoneO] = useState([]);
  const [selectedZoneW, setSelectedZoneW] = useState([]);
  const [newRatePlansUnits, setNewRatePlansUnits] = useState([]);
  const [timeCheckedAll, setTimeCheckedAll] = useState([]);
  const [allDefaultValues, setAllDefaultValues] = useState({
    name: ratePlanItem ? ratePlanItem?.name : "",
    name_vi: ratePlanItem ? ratePlanItem?.name_vi : "",
    type: ratePlanItem
      ? typeOptions.filter((i) => i.id === ratePlanItem?.type)[0]
      : {},
    program_applied: ratePlanItem ? ratePlanItem?.program_applied : "",
    program_applied_vi: ratePlanItem ? ratePlanItem?.program_applied_vi : "",
    show_id: ratePlanItem ? ratePlanItem?.show : {},
    venue_id: ratePlanItem ? ratePlanItem?.venue : {},
    from_date: ratePlanItem ? moment(ratePlanItem.from_date).toDate() : "",
    to_date: ratePlanItem ? moment(ratePlanItem.to_date).toDate() : "",
    description: ratePlanItem ? ratePlanItem?.description : "",
    description_vi: ratePlanItem ? ratePlanItem?.description_vi : "",
    status_step1: ratePlanItem ? ratePlanItem?.status : 1,
    excludes: [],
  });
  const [currentAction, setCurrentAction] = useState({
    userInfo: true,
    address: false,
    socials: false,
    profile: false,
  });
  const [ratePlanInfo, setRatePlanInfo] = useState();
  const [activeBullets, setActiveBullets] = useState({
    userInfo: true,
    address: false,
    socials: false,
    profile: false,
  });
  const [preViewImage, setPreviewImage] = useState("");
  const [isCreate, setIsCreate] = useState(true);
  const [currentIDandCode, setCurrentIDandCode] = useState({});
  const [listUnitView, setListUnitView] = useState([]);
  const [isActiveAll, setIsActiveAll] = useState(false);
  const [groupedShowData, setGroupedShowData] = useState([]);
  const [checkedDefault, setCheckedDefault] = useState([]);
  const [
    listCheckedRateplanUnitChoose,
    setListCheckedRateplanUnitChoose,
  ] = useState([]);
  const userInfoTab = useRef();
  const addressTab = useRef();
  const socialsTab = useRef();
  const profileTab = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    watch: watchSearch,
    reset: resetSearch,
    setValue: setValueSearch,
  } = useForm();
  const channelSearch = watchSearch("channelSearch");
  const unitSearch = watchSearch("unitSearch");
  const handleCancelConfirm = () => {
    onClose();
    if (currentAction.userInfo) {
      setActiveTab(0);
      setActiveBullets({
        userInfo: true,
        address: false,
        socials: false,
        profile: false,
      });
    } else if (currentAction.address) {
      setActiveTab(1);
      setActiveBullets({
        userInfo: true,
        address: true,
        socials: false,
        profile: false,
      });
    } else if (currentAction.socials) {
      setActiveTab(2);
      setActiveBullets({
        userInfo: true,
        address: true,
        socials: true,
        profile: false,
      });
    } else {
      setActiveTab(3);
      setActiveBullets({
        userInfo: true,
        address: true,
        socials: true,
        profile: true,
      });
    }
  };
  const handleConfirm = () => {
    onClose();
    Object.keys(formState.dirtyFields).map((fieldName) => {
      setValue(fieldName, ratePlanItem[fieldName], { shouldDirty: false });
    });
    reset({}, { keepValues: true });
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required").max(255),
    name_vi: Yup.string().required("This field is required").max(255),
    show_id: Yup.object().required("This field is required"),
    venue_id: Yup.object().required("This field is required"),
    program_applied_vi: Yup.string()
      .required("This field is required")
      .max(255),
    type: Yup.object().required("This field is required"),
    from_date: Yup.date().required("This field is required"),
    to_date: Yup.date()
      .required("This field is required")
      .test(
        "date-range",
        "Date To cannot be less than Date From",
        function (value) {
          const { from_date } = this.parent;
          return !from_date || !value || new Date(value) >= new Date(from_date);
        }
      ),
    from_showdate:
      activeTab === 2 && Yup.date().required("This field is required"),
    to_showdate:
      activeTab === 2 &&
      Yup.date().test(
        "date-range-2",
        "Date To cannot be less than Date From",
        function (value) {
          const { from_showdate } = this.parent;
          return (
            !from_showdate ||
            !value ||
            new Date(value) >= new Date(from_showdate)
          );
        }
      ),
    program_applied: Yup.string().required("This field is required").max(255),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    watch,
    setValue,
  } = useForm({ defaultValues: allDefaultValues, ...formOptions });
  const {
    errors,
    isSubmitting,
    dirtyFields,
    isDirty,
    isSubmitSuccessful,
  } = formState;
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(async () => {
    await bookingActions.getListZones({ status: 1 }, 0, "");
    if (ratePlanItem) {
      setCurrentIDandCode({
        id: ratePlanItem.id,
        code: ratePlanItem.code,
      });
      if (isCopy) {
        // const rateplan_units_current = ratePlanItem.rateplan_units
        // const unitsID = listCheckedChoose.map((i) => i.values.id);
        // const data = {
        //   commissions: commissionsID,
        //   units: unitsID,
        //   status: 1,
        // };
        // const res = await ratePlanActions.createRatePlanUnits(
        //   data,
        //   ratePlanInfo.id
        // );
        setIsCreate(true);
      } else {
        setIsCreate(false);
      }
      setValue("name", ratePlanItem.name);
      setValue("name_vi", ratePlanItem.name_vi);
      setValue(
        "type",
        typeOptions.filter((i) => i.id === ratePlanItem.type)[0]
      );
      setValue("program_applied", ratePlanItem.program_applied);
      setValue("program_applied_vi", ratePlanItem.program_applied_vi);
      setValue("show_id", ratePlanItem.show);
      setValue("venue_id", ratePlanItem.venue);
      setValue("from_date", moment(ratePlanItem.from_date).toDate());
      setValue("to_date", moment(ratePlanItem.to_date).toDate());
      setValue("description", ratePlanItem.description);
      setValue("description_vi", ratePlanItem.description_vi);
      setValue("status_step1", ratePlanItem.status === 1 ? true : false);
      setRatePlanInfo({
        code: ratePlanItem.code,
        name: ratePlanItem.name,
        program_applied: ratePlanItem.program_applied,
        type: typeRatePlan(ratePlanItem.type),
        show: ratePlanItem.show.name,
        venue: ratePlanItem.venue.name,
        id: ratePlanItem.id,
        showID: ratePlanItem.show.id,
        venueID: ratePlanItem.venue.id,
      });
      const allRatePlanDefault = {};
      if (ratePlanItem.rateplan_configs) {
        let zoneIDs = [];
        let pricePromotionZone1 = [];
        let pricePromotionZone2 = [];
        let pricePromotionZone3 = [];

        ratePlanItem.rateplan_configs.forEach((i) => {
          // setAllDefaultValues(preState => ({...preState, ...[`adult_amount[${parseInt(i.zone_id)}]`] = i.adult_amount }))
          allRatePlanDefault[`adult_amount[${parseInt(i.zone_id)}]`] =
            i.adult_amount;
          allRatePlanDefault[`child_amount[${parseInt(i.zone_id)}]`] =
            i.child_amount;
          allRatePlanDefault[`adult_extra_cost[${parseInt(i.zone_id)}]`] =
            i.adult_extra_cost;
          allRatePlanDefault[`extra_adult_amount[${parseInt(i.zone_id)}]`] =
            i.extra_adult_amount;
          allRatePlanDefault[`extra_child_amount[${parseInt(i.zone_id)}]`] =
            i.extra_child_amount;

          setValue(`adult_amount[${parseInt(i.zone_id)}]`, i.adult_amount);
          setValue(`child_amount[${parseInt(i.zone_id)}]`, i.child_amount);
          setValue(
            `adult_extra_cost[${parseInt(i.zone_id)}]`,
            i.adult_extra_cost
          );
          setValue(
            `extra_adult_amount[${parseInt(i.zone_id)}]`,
            i.extra_adult_amount
          );
          setValue(
            `extra_child_amount[${parseInt(i.zone_id)}]`,
            i.extra_child_amount
          );
          if (ratePlanItem.type === 6) {
            switch (i.zone_id) {
              case 1:
                pricePromotionZone1.push(i);
                break;
              case 2:
                pricePromotionZone2.push(i);
                break;
              case 3:
                pricePromotionZone3.push(i);
                break;
              default:
                break;
            }
            setValue(
              `adult_amount[${i.floor}-${i.seat_number}]`,
              i.adult_amount
            );
          }
          zoneIDs.push(i.zone_id.toString());
        });
        setSelectedZoneA(pricePromotionZone1);
        setValue("seating_plans_zone_1", pricePromotionZone1);
        setSelectedZoneO(pricePromotionZone2);
        setValue("seating_plans_zone_2", pricePromotionZone2);
        setSelectedZoneW(pricePromotionZone3);
        setValue("seating_plans_zone_3", pricePromotionZone3);
        setValue("zone_id", [...new Set(zoneIDs)]);
        allRatePlanDefault["zone_id"] = zoneIDs;
        allRatePlanDefault["from_hour"] = moment(
          ratePlanItem.rateplan_configs[0].from_hour,
          "HH:mm"
        ).toDate();
        allRatePlanDefault["to_hour"] = moment(
          ratePlanItem.rateplan_configs[0].to_hour,
          "HH:mm"
        ).toDate();
        allRatePlanDefault["campaign_code"] =
          ratePlanItem.rateplan_configs[0].campaign_code;
        allRatePlanDefault["campaign_medium"] =
          ratePlanItem.rateplan_configs[0].campaign_code;
        allRatePlanDefault["image"] =
          ratePlanItem.rateplan_configs[0].image_url;
        allRatePlanDefault["content"] =
          ratePlanItem.rateplan_configs[0].content;
        allRatePlanDefault["content_vi"] =
          ratePlanItem.rateplan_configs[0].content_vi;
        allRatePlanDefault["package_limit"] =
          ratePlanItem.rateplan_configs[0].package_limit;
        allRatePlanDefault["redirect_url"] =
          ratePlanItem.rateplan_configs[0].redirect_url;
        allRatePlanDefault["discount_rate"] =
          ratePlanItem.rateplan_configs[0].discount_rate;
        allRatePlanDefault["before_hours"] =
          ratePlanItem.rateplan_configs[0].before_hours;
        allRatePlanDefault["max"] = ratePlanItem.rateplan_configs[0].max;
        allRatePlanDefault["min"] = ratePlanItem.rateplan_configs[0].min;
        allRatePlanDefault["combo_description"] =
          ratePlanItem.rateplan_configs[0].combo_description;
        allRatePlanDefault["combo_description_vi"] =
          ratePlanItem.rateplan_configs[0].combo_description_vi;
        allRatePlanDefault["status_step2"] =
          ratePlanItem.rateplan_configs[0].status;
        allRatePlanDefault["adult"] = ratePlanItem.rateplan_configs[0].adult;
        allRatePlanDefault["child"] = ratePlanItem.rateplan_configs[0].child;

        setAllDefaultValues((preState) => ({
          ...preState,
          ...allRatePlanDefault,
        }));

        setValue(
          "from_hour",
          moment(ratePlanItem.rateplan_configs[0].from_hour, "HH:mm").toDate()
        );
        setValue(
          "to_hour",
          moment(ratePlanItem.rateplan_configs[0].to_hour, "HH:mm").toDate()
        );
        setValue(
          "campaign_code",
          ratePlanItem.rateplan_configs[0].campaign_code
        );
        setValue(
          "campaign_medium",
          ratePlanItem.rateplan_configs[0].campaign_medium
        );
        setValue("image", ratePlanItem.rateplan_configs[0].image_url);
        if (ratePlanItem.rateplan_configs[0].image_url) {
          setPreviewImage((preS) => ({
            ...preS,
            url: `${ratePlanItem.rateplan_configs[0].image_url}`,
          }));
        }
        setValue("content", ratePlanItem.rateplan_configs[0].content);
        setValue("content_vi", ratePlanItem.rateplan_configs[0].content_vi);
        setValue(
          "package_limit",
          ratePlanItem.rateplan_configs[0].package_limit
        );
        setValue("redirect_url", ratePlanItem.rateplan_configs[0].redirect_url);
        setValue(
          "discount_rate",
          ratePlanItem.rateplan_configs[0].discount_rate
        );
        setValue("before_hours", ratePlanItem.rateplan_configs[0].before_hours);
        setValue("max", ratePlanItem.rateplan_configs[0].max);
        setValue("min", ratePlanItem.rateplan_configs[0].min);
        setValue(
          "combo_description",
          ratePlanItem.rateplan_configs[0].description
        );
        setValue(
          "combo_description_vi",
          ratePlanItem.rateplan_configs[0].description_vi
        );
        setValue("status_step2", ratePlanItem.rateplan_configs[0].status);
        setValue("adult", ratePlanItem.rateplan_configs[0].adult);
        setValue("child", ratePlanItem.rateplan_configs[0].child);
      }
      if (ratePlanItem.from_showdate) {
        allRatePlanDefault["from_showdate"] = moment(
          ratePlanItem.from_showdate
        ).toDate();
      }
      if (ratePlanItem.to_showdate) {
        allRatePlanDefault["to_showdate"] = setValue(
          "to_showdate",
          moment(ratePlanItem.to_showdate).toDate()
        );
      }
      ratePlanItem.from_showdate &&
        setValue("from_showdate", moment(ratePlanItem.from_showdate).toDate());
      ratePlanItem.to_showdate &&
        setValue("to_showdate", moment(ratePlanItem.to_showdate).toDate());
    }
    return () => {
      setValue("zone_id", []);
    };
  }, []);
  const image = watch("image");
  const to_showdate = watch("to_showdate");
  const from_showdate = watch("from_showdate");
  const channel = watch("channel");
  const unitSearchAdd = watch("unitSearchAdd");
  const zone_id = watch("zone_id");
  const excludes = watch("excludes");
  const commissions = watch("commissions");
  const status_step1_watch = watch("status_step1");
  const status_step2_watch = watch("status_step2");
  useEffect(async () => {
    if (channel) {
      setIsFetching(true);
      recallApi({
        pageIndex: 0,
        pageSize:
          localStorage && localStorage.getItem("currentPageSizeStore")
            ? localStorage.getItem("currentPageSizeStore")
            : 10,
      });
      setValue("unitSearchAdd", "");
      setIsFetching(false);
    }
  }, [channel]);
  useEffect(async () => {
    if (channelSearch) {
      setValueSearch("unitSearch", null);
      setIsFetching(true);
      const res = await bookingActions.getListUnits(
        {
          channel_id: channelSearch.id,
        },
        0,
        ""
      );
      setListUnitView(res.data.data || []);
      setListUnit([]);
      setIsFetching(false);
    }
  }, [channelSearch]);

  useEffect(async () => {
    if (image && image?.length > 0) {
      const formData = new FormData();
      formData.append("file", image[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewImage({ image_url: data.filename, url: data.url });
    }
  }, [image]);
  useEffect(() => {
    if (from_showdate && activeTab === 2) {
      bookingActions.getShowsSchedule({
        show_id: ratePlanInfo.showID || "",
        venue_id: "",
        status: "Active",
        from_date: from_showdate,
        to_date: to_showdate || null,
      });
    }
  }, [to_showdate, from_showdate, activeTab]);
  useEffect(() => {
    if (ratePlanUnits) {
      const newRatePlansUnits = ratePlanUnits.map((i) => ({
        ...i,
        typeSwitch: "rateplanUnit",
        commissions1: i.commissions,
        commissions2: i.commissions,
        filters: {
          channel_id: channelSearch?.id || "",
          unit_ids: unitSearch?.map((i) => i.id).join(",") ?? "",
        },
      }));
      setNewRatePlansUnits(newRatePlansUnits);
    }
  }, [ratePlanUnits]);
  useEffect(() => {
    if (!isCreate) {
      setCurrentAction({
        userInfo: true,
        address: true,
        socials: true,
        profile: true,
      });
    }
  }, [isCreate]);
  useEffect(() => {
    if (ratePlanUnits.length > 0 && activeTab === 3) {
      const lastRatePlanUnit =
        ratePlanUnits[ratePlanUnits.length - 1]?.commissions;
      lastRatePlanUnit.forEach((i) => {
        setValue(`commission1[${parseInt(i.zone_id)}]`, i.com1);
        setValue(`commission2[${parseInt(i.zone_id)}]`, i.com2);
      });
    }
  }, [activeTab]);
  useEffect(() => {
    if (isCreate) {
      if (currentAction.address) {
        addressTab.current.click();
      } else if (currentAction.socials) {
        socialsTab.current.click();
      } else {
        profileTab.current.click();
      }
    }
  }, [currentAction]);
  useEffect(async () => {
    if (ratePlanInfo?.type === "PROMOTION" && activeTab === 1) {
      if (Array.isArray(zone_id) && zone_id?.length > 0) {
        zone_id.forEach(async (i) => {
          const res = await ratePlanActions.getListSeatingPlanDetail({
            show_id: ratePlanInfo.showID,
            venue_id: ratePlanInfo.venueID,
            zone_id: i,
          });
          if (i === "1") {
            setSeatingPlanZoneA(res.data.data);
          } else if (i === "2") {
            setSeatingPlanZoneO(res.data.data);
          } else {
            setSeatingPlanZoneW(res.data.data);
          }
        });
      }
    }
  }, [zone_id, activeTab]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    const IDUnitChoosed = ratePlanUnits.map((i) => i.unit_id);
    const queryString = IDUnitChoosed.map((value) => `omit_ids=${value}`).join(
      "&"
    );
    await bookingActions.getListUnits(
      {
        channel_id: channel ? channel.id : "",
        omit_ids: channel ? queryString : unitSearchAdd,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };

  const recallApi2 = async (fetchDataOptions) => {
    if (ratePlanItem) {
      setIsFetching(true);
      const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
      await ratePlanActions.getListRatePlansUnits(
        {
          channel_id: channelSearch?.id || "",
          unit_ids,
          sort_type: fetchDataOptions.sort_type,
          sort_field: fetchDataOptions.sort_field,
        },
        ratePlanItem.id,
        fetchDataOptions.pageIndex,
        fetchDataOptions.pageSize
      );
      setIsFetching(false);
    }
  };
  const searchUnitByName = async () => {
    if (channel) {
      setIsFetching(true);
      const IDUnitChoosed = ratePlanUnits.map((i) => i.unit_id);
      const queryString = IDUnitChoosed.map(
        (value) => `omit_ids=${value}`
      ).join("&");
      await bookingActions.getListUnits(
        { channel_id: channel.id, omit_ids: queryString, name: unitSearchAdd },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
      setIsFetching(false);
    } else {
      await bookingActions.getListUnits(
        {
          name: unitSearchAdd,
          status: 1,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
    }
  };
  useEffect(() => {
    if (showsSchedule && showsSchedule.length > 0) {
      const groupedData = showsSchedule.reduce((result, item) => {
        const showTime = item.show_time;
        if (!result[showTime]) {
          result[showTime] = [];
        }
        result[showTime].push(item);

        return result;
      }, {});
      setGroupedShowData(groupedData);
      let checked = [];
      groupedData &&
        Object.entries(groupedData).forEach(([key, value]) => {
          value.forEach((item) => {
            checked.push(String(item.id));
          });
        });
      setCheckedDefault(checked);

      if (!isCreate) {
        const excludeShowScheduleDetails = ratePlanItem.exclude_show_schedule_details.map(
          (i) => i.id.toString()
        );
        const uniqueValues = checked
          .filter((value) => !excludeShowScheduleDetails.includes(value))
          .concat(
            excludeShowScheduleDetails.filter(
              (value) => !checked.includes(value)
            )
          );
        setValue("excludes", uniqueValues);
      } else {
        setValue("excludes", checked);
      }
    }
  }, [showsSchedule]);
  useEffect(() => {
    if (to_showdate === null) {
      setValue("to_showdate", undefined);
    }
  }, [to_showdate]);
  useEffect(() => {
    if (excludes && excludes.length > 0) {
      const matchingKeys = Object.keys(groupedShowData).filter((key) =>
        groupedShowData[key].every((item) =>
          excludes.includes(item.id.toString())
        )
      );
      setTimeCheckedAll(matchingKeys);
    }
  }, [excludes]);
  const checkedAllShowTime = (val, key) => {
    const showTimeID = val.map((i) => i.id.toString());
    setValue("excludes", [...new Set([...excludes, ...showTimeID])]);
    setTimeCheckedAll([...timeCheckedAll, ...[key]]);
  };
  const removeShowTime = (val, key) => {
    const filtered = excludes.filter(
      (idA) => !val.some((itemB) => itemB.id.toString() === idA)
    );
    const newTimeCheckedAll = timeCheckedAll.filter((i) => i !== key);
    setValue("excludes", filtered);
    setTimeCheckedAll(newTimeCheckedAll);
  };
  async function onSubmit({
    name,
    name_vi,
    show_id,
    venue_id,
    program_applied_vi,
    type,
    from_date,
    to_date,
    program_applied,
    status_step1,
    status_step2,
    package_limit,
    child,
    adult,
    zone_id,
    min,
    max,
    description_vi,
    description,
    combo_description,
    combo_description_vi,
    content,
    content_vi,
    before_hours,
    discount_rate,
    to_hour,
    from_hour,
    to_showdate,
    from_showdate,
    redirect_url,
    campaign_code,
    campaign_medium,
    excludes,
  }) {
    try {
      if (isCreate) {
        if (currentAction.userInfo) {
          const {
            data: { data: ratePlanBasic },
          } = await ratePlanActions.createRatePlan({
            name,
            name_vi,
            show_id: show_id.id,
            venue_id: venue_id.id,
            program_applied_vi,
            type: type.id,
            from_date,
            to_date,
            program_applied,
            status: status_step1 ? 1 : 2,
            description,
            description_vi,
          });
          if (ratePlanBasic) {
            setRatePlanInfo({
              code: ratePlanBasic.code,
              name: ratePlanBasic.name,
              program_applied: ratePlanBasic.program_applied,
              type: typeRatePlan(ratePlanBasic.type),
              show: ratePlanBasic.show.name,
              venue: ratePlanBasic.venue.name,
              id: ratePlanBasic.id,
              showID: ratePlanBasic.show.id,
              venueID: ratePlanBasic.venue.id,
            });

            setActiveBullets({
              userInfo: true,
              address: true,
              socials: false,
              profile: false,
            });
            setCurrentAction({
              userInfo: false,
              address: true,
              socials: false,
              profile: false,
            });
          }
          setActiveTab(1);
        }
        if (currentAction.address) {
          if (!zone_id || zone_id?.length < 1) {
            toast({
              description: "Please choose at least one zone",
              status: "error",
              duration: 3000,
              position: "top-right",
            });
            return;
          }
          let zonesID = Array.isArray(zone_id) ? zone_id : [zone_id];
          let zones = zonesID.map((i) => {
            const parseValue = (field) => {
              const fieldValue = watch(`${field}[${parseInt(i)}]`);
              return typeof fieldValue === "number"
                ? fieldValue
                : parseInt(fieldValue?.replace(/,/g, "") || 0);
            };
            return {
              zone_id: parseInt(i),
              adult_amount: parseValue("adult_amount"),
              prepaid:
                ratePlanInfo.type === "VOUCHER"
                  ? parseValue("child_amount")
                  : undefined,
              child_amount: parseValue("child_amount"),
              adult_extra_cost: parseValue("adult_extra_cost"),
              extra_adult_amount: parseValue("extra_adult_amount"),
              extra_child_amount: parseValue("extra_child_amount"),
            };
          });
          if (ratePlanInfo.type === "PROMOTION") {
            let zonesPromotion = [];
            zonesID.forEach((zone) => {
              const seatPricePromotion = watch(`seating_plans_zone_${zone}`);
              if (seatPricePromotion)
                seatPricePromotion.forEach((i) => {
                  let seatValuePrice = {};
                  const valueSeat = watch(
                    `adult_amount[${i?.floor}-${i?.seat_number}]`
                  );
                  if (typeof valueSeat !== "number") {
                    seatValuePrice.adult_amount = parseInt(
                      valueSeat?.replace(/,/g, "") || 0
                    );
                  } else {
                    seatValuePrice.adult_amount = valueSeat;
                  }
                  seatValuePrice.seat_number = i.seat_number;
                  seatValuePrice.floor = i.floor;
                  seatValuePrice.zone_id = i.zone_id;
                  zonesPromotion.push(seatValuePrice);
                });
            });
            zones = zonesPromotion;
          }
          const data = {
            package_limit: parseInt(package_limit),
            child: parseInt(child),
            adult: parseInt(adult),
            zones,
            status: status_step2 ? 1 : 2,
            before_hours: parseInt(before_hours),
            content,
            content_vi,
            description: combo_description,
            description_vi: combo_description_vi,
            min: parseInt(min),
            max: parseInt(max),
            discount_rate: parseInt(discount_rate),
            to_hour: moment(to_hour).format("HH:mm"),
            from_hour: moment(from_hour).format("HH:mm"),
            redirect_url,
            campaign_code,
            campaign_medium,
            image_url: preViewImage.url,
          };
          await ratePlanActions.createRatePlanConfig(data, ratePlanInfo.id);
          ratePlanActions.getAllRatePlans(
            {
              code: "",
              name: "",
              show_id: "",
              venue_id: "",
              type: "",
              status: 1,
              channel_id: "",
            },
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
              ? localStorage.getItem("currentPageSizeStore")
              : 10
          );
          setActiveBullets({
            userInfo: true,
            address: true,
            socials: true,
            profile: false,
          });
          setCurrentAction({
            userInfo: false,
            address: false,
            socials: true,
            profile: false,
          });
          setActiveTab(2);
        }
        if (currentAction.socials) {
          let data = {
            to_showdate,
            from_showdate,
          };
          if (excludes.length > 0) {
            const uniqueValues = checkedDefault
              .filter((value) => !excludes.includes(value))
              .concat(
                excludes.filter((value) => !checkedDefault.includes(value))
              );
            const parseExcludes = uniqueValues.map((i) => parseInt(i));
            data.excludes = parseExcludes;
          }
          await ratePlanActions.createRatePlanShowTime(data, ratePlanInfo.id);
          setActiveBullets({
            userInfo: true,
            address: true,
            socials: true,
            profile: true,
          });
          setCurrentAction({
            userInfo: false,
            address: false,
            socials: false,
            profile: true,
          });
          setActiveTab(3);
        }
        if (currentAction.profile) {
          onCloseModal();
          reset();
          setActiveBullets({
            userInfo: false,
            address: false,
            socials: false,
            profile: false,
          });
          setCurrentAction({
            userInfo: true,
            address: false,
            socials: false,
            profile: false,
          });
          setActiveTab(0);
        }
      } else {
        if (currentAction.userInfo) {
          await ratePlanActions.updateRatePlan(
            {
              name,
              name_vi,
              show_id: show_id.id,
              venue_id: venue_id.id,
              program_applied_vi,
              type: type.id,
              from_date,
              to_date,
              program_applied,
              status: status_step1 ? 1 : 2,
              description,
              description_vi,
              code: currentIDandCode.code,
            },
            currentIDandCode.id
          );
          if (!ratePlanItem.rateplan_configs) {
            setIsCreate(true);
          }

          setActiveBullets({
            userInfo: true,
            address: true,
            socials: false,
            profile: false,
          });
          setCurrentAction({
            userInfo: false,
            address: true,
            socials: false,
            profile: false,
          });
          setActiveTab(1);
        }
        if (currentAction.address && activeBullets.address) {
          let zoneIDs = [];
          if (typeof zone_id === "string") {
            zoneIDs.push(zone_id);
          } else {
            zoneIDs = zone_id;
          }
          let zones = zoneIDs.map((i) => ({
            zone_id: parseInt(i),
            adult_amount: parseInt(watch(`adult_amount[${parseInt(i)}]`)),
            child_amount: parseInt(watch(`child_amount[${parseInt(i)}]`)),
            adult_extra_cost: parseInt(watch(`adult_extra_cost[${parseInt(i)}]`) || 0),
            extra_adult_amount: parseInt(watch(`extra_adult_amount[${parseInt(i)}]`) || 0),
            extra_child_amount: parseInt(watch(`extra_child_amount[${parseInt(i)}]`) || 0)
          }));
          if (ratePlanInfo.type === "PROMOTION") {
            let zonesPromotion = [];
            zoneIDs.forEach((zone) => {
              if (zone === "1") {
                selectedZoneA.forEach((i) => {
                  let seatValuePrice = {};
                  const valueSeat = watch(
                    `adult_amount[${i?.floor}-${i?.seat_number}]`
                  );
                  if (typeof valueSeat !== "number") {
                    seatValuePrice.adult_amount = parseInt(
                      valueSeat?.replace(/,/g, "") || 0
                    );
                  } else {
                    seatValuePrice.adult_amount = valueSeat;
                  }
                  seatValuePrice.seat_number = i.seat_number;
                  seatValuePrice.floor = i.floor;
                  seatValuePrice.zone_id = i.zone_id;
                  zonesPromotion.push(seatValuePrice);
                });
              } else if (zone === "2") {
                selectedZoneO.forEach((i) => {
                  let seatValuePrice = {};
                  const valueSeat = watch(
                    `adult_amount[${i?.floor}-${i?.seat_number}]`
                  );
                  if (typeof valueSeat !== "number") {
                    seatValuePrice.adult_amount = parseInt(
                      valueSeat?.replace(/,/g, "") || 0
                    );
                  } else {
                    seatValuePrice.adult_amount = valueSeat;
                  }
                  seatValuePrice.seat_number = i.seat_number;
                  seatValuePrice.floor = i.floor;
                  seatValuePrice.zone_id = i.zone_id;
                  zonesPromotion.push(seatValuePrice);
                });
              } else {
                selectedZoneW.forEach((i) => {
                  let seatValuePrice = {};
                  const valueSeat = watch(
                    `adult_amount[${i?.floor}-${i?.seat_number}]`
                  );
                  if (typeof valueSeat !== "number") {
                    seatValuePrice.adult_amount = parseInt(
                      valueSeat?.replace(/,/g, "") || 0
                    );
                  } else {
                    seatValuePrice.adult_amount = valueSeat;
                  }
                  seatValuePrice.seat_number = i.seat_number;
                  seatValuePrice.floor = i.floor;
                  seatValuePrice.zone_id = i.zone_id;
                  zonesPromotion.push(seatValuePrice);
                });
              }
            });
            zones = zonesPromotion;
          }
          const data = {
            package_limit: parseInt(package_limit),
            child: parseInt(child),
            adult: parseInt(adult),
            zones,
            status: status_step2 ? 1 : 2,
            before_hours: parseInt(before_hours),
            content,
            content_vi,
            description: combo_description,
            description_vi: combo_description_vi,
            min: parseInt(min),
            max: parseInt(max),
            discount_rate: parseInt(discount_rate),
            to_hour: moment(to_hour).format("HH:mm"),
            from_hour: moment(from_hour).format("HH:mm"),
            redirect_url,
            campaign_code,
            campaign_medium,
            image_url: preViewImage.url,
          };
          await ratePlanActions.updateRatePlanConfig(data, currentIDandCode.id);
          updateNewRatePlan(currentIDandCode.id);
          setActiveBullets({
            userInfo: true,
            address: true,
            socials: true,
            profile: false,
          });
          setCurrentAction({
            userInfo: false,
            address: false,
            socials: true,
            profile: false,
          });
          setActiveTab(2);
        }
        if (currentAction.socials && activeBullets.socials) {
          let data = {
            to_showdate,
            from_showdate,
          };
          if (excludes.length > 0) {
            const uniqueValues = checkedDefault
              .filter((value) => !excludes.includes(value))
              .concat(
                excludes.filter((value) => !checkedDefault.includes(value))
              );
            const parseExcludes = uniqueValues.map((i) => parseInt(i));
            data.excludes = parseExcludes;
          }
          await ratePlanActions.updateRatePlanShowTime(
            data,
            currentIDandCode.id
          );
          updateNewRatePlan(currentIDandCode.id);
          setActiveBullets({
            userInfo: true,
            address: true,
            socials: true,
            profile: true,
          });
          setCurrentAction({
            userInfo: false,
            address: false,
            socials: false,
            profile: true,
          });
          setActiveTab(3);
        }
        if (currentAction.profile && activeBullets.profile) {
          onCloseModal();
          reset();
          setActiveBullets({
            userInfo: false,
            address: false,
            socials: false,
            profile: false,
          });
          setCurrentAction({
            userInfo: true,
            address: false,
            socials: false,
            profile: false,
          });
          setActiveTab(0);
        }
      }
      // if(isCopy && currentAction.profile){
      //   const rateplan_units_current = ratePlanItem.rateplan_units

      // }
      if (closeModal) {
        onCloseModal();
        reset();
        setListUnit([]);
      }
    } catch (error) {
      toast({
        description: `${error}`,
        status: "error",
        duration: 2000,
      });
      console.warn(error, "error");
    }
  }

  const addSeats = (zone) => {
    switch (zone) {
      case "A":
        const zoneA = watch("seating_plans_zone_1");
        setSelectedZoneA(zoneA);
        break;
      case "O":
        const zoneO = watch("seating_plans_zone_2");
        setSelectedZoneO(zoneO);
        break;
      case "W":
        const zoneW = watch("seating_plans_zone_3");
        setSelectedZoneW(zoneW);
        break;
      default:
        break;
    }
  };
  const deleteSeat = (seat, zone) => {
    switch (zone) {
      case "A":
        const newSelectedSeatA = selectedZoneA.filter(
          (i) => i.seat_number !== seat
        );
        setSelectedZoneA(newSelectedSeatA);
        setValue("seating_plans_zone_1", newSelectedSeatA);
        break;
      case "O":
        const newSelectedSeatO = selectedZoneO.filter(
          (i) => i.seat_number !== seat
        );
        setSelectedZoneO(newSelectedSeatO);
        setValue("seating_plans_zone_2", newSelectedSeatO);
        break;
      case "W":
        const newSelectedSeatW = selectedZoneW.filter(
          (i) => i.seat_number !== seat
        );
        setSelectedZoneW(newSelectedSeatW);
        setValue("seating_plans_zone_3", newSelectedSeatW);
        break;
      default:
        break;
    }
  };
  const getValueChecked = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setListCheckedChoose(checkedIDs);
    }
  };
  const getValueChecked2 = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setListCheckedRateplanUnitChoose(checkedIDs);
    }
  };
  const confirmSwitchAll = async () => {
    const rateplanIDs = ratePlanUnits
      .filter((i) => listCheckedRateplanUnitChoose.includes(i.id.toString()))
      .map((item) => item.unit_id);
    const updateRateplanUnits = isActiveAll
      ? ratePlanActions.updateActiveRateplanUnits
      : ratePlanActions.updateInactiveRateplanUnits;

    try {
      const res = await updateRateplanUnits(
        {
          ids: rateplanIDs,
        },
        currentIDandCode.id
      );
      if (res.status === 200) {
        onCloseConfirm();
        const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
        await ratePlanActions.getListRatePlansUnits(
          {
            channel_id: channelSearch?.id || "",
            unit_ids,
          },
          currentIDandCode.id,
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
            ? localStorage.getItem("currentPageSizeStore")
            : 10
        );
        setListCheckedChoose([]);
        if (isActiveAll) {
          toast({
            description: "Active RatePlan Unit successful",
            status: "success",
            duration: 3000,
          });
        } else {
          toast({
            description: "Inactive RatePlan Unit successful",
            status: "success",
            duration: 3000,
          });
        }
        setClearAllCheckBox(Math.random());
      }
    } catch (error) {
      toast({
        description: `${error.response}`,
        status: "error",
        duration: 3000,
      });
    }
  };
  const confirmAdd = async (e, type) => {
    e.preventDefault();
    if (!commissions || commissions?.length === 0) {
      toast({
        description: "Please add commissions",
        status: "error",
        duration: 2000,
      });
      return;
    }
    if (currentAction.profile) {
      let commissionsItem = commissions;
      if (typeof commissions === "string") {
        commissionsItem = Array(commissions);
      }
      const commissionsID = commissionsItem.map((i) => ({
        zone_id: parseInt(i),
        com1:
          typeof watch(`commission1[${parseInt(i)}]`) === "number"
            ? watch(`commission1[${parseInt(i)}]`)
            : parseInt(
                watch(`commission1[${parseInt(i)}]`)?.replace(/,/g, "") || 0
              ),
        com2:
          typeof watch(`commission2[${parseInt(i)}]`) === "number"
            ? watch(`commission2[${parseInt(i)}]`)
            : parseInt(
                watch(`commission2[${parseInt(i)}]`)?.replace(/,/g, "") || 0
              ),
      }));
      const unitsID = listCheckedChoose.map((i) => parseInt(i));
      let listUnitIDS = [];
      if (type === "confirmAll") {
        const res = await bookingActions.getListUnits(
          { channel_id: channel ? channel.id : "" },
          0,
          0
        );
        listUnitIDS = res.data.data.map((i) => i.id);
      }
      const data = {
        commissions: commissionsID,
        units: type === "confirmAll" ? listUnitIDS : unitsID,
        status: 1,
      };
      const res = await ratePlanActions.createRatePlanUnits(
        data,
        ratePlanInfo.id
      );
      if (res.status === 200) {
        toast({
          description: "Add Units for Rate Plan successful",
          status: "success",
          duration: 2000,
        });
        setListUnitChoosed(res.data.data);
      }
      const IDUnitChoosed = res.data.data.map((i) => i.unit_id);
      const queryString = IDUnitChoosed.map(
        (value) => `omit_ids=${value}`
      ).join("&");
      setIsFetching(true);
      await bookingActions.getListUnits(
        { channel_id: channel?  channel.id: "", omit_ids: queryString },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
      setIsFetching(false);
      setValue("commissions", []);
      setListCheckedChoose([]);
      if (!isCreate) {
        const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
        await ratePlanActions.getListRatePlansUnits(
          {
            channel_id: channelSearch?.id || "",
            unit_ids,
          },
          ratePlanItem.id,
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
            ? localStorage.getItem("currentPageSizeStore")
            : 10
        );
      }
    }
  };
  async function onSubmitSearch() {
    if (ratePlanItem) {
      const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
      await ratePlanActions.getListRatePlansUnits(
        {
          channel_id: channelSearch?.id || "",
          unit_ids,
        },
        ratePlanItem.id,
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
    }
  }
  const isCheckZone = (zone) => {
    if (zone_id && Array.isArray(zone_id)) {
      return zone_id?.includes(zone?.id?.toString());
    } else {
      return Array(zone_id).includes(zone?.id?.toString());
    }
  };
  return (
    <Flex direction="column" minH="89vh" align="center">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Tabs index={activeTab} isLazy variant="unstyled" mt="24px">
          <TabList display="flex" align="center" justifyContent="center">
            <Tab
              ref={userInfoTab}
              _focus={{}}
              w={{ sm: "80px", md: "200px" }}
              isDisabled={isCreate ? !currentAction.userInfo : false}
              onClick={() => {
                if (isCreate) return;
                setActiveBullets({
                  userInfo: true,
                  address: false,
                  socials: false,
                  profile: false,
                });
                setCurrentAction({
                  userInfo: true,
                  address: false,
                  socials: false,
                  profile: false,
                });
                setActiveTab(0);
              }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: "80px", md: "200px" },
                  height: "3px",
                  bg: activeBullets.address ? "black" : "blue.300",
                  left: { sm: "12px", md: "50px" },
                  top: { sm: activeBullets.userInfo ? "6px" : "4px", md: null },
                  position: "absolute",
                  bottom: activeBullets.userInfo ? "40px" : "38px",

                  transition: "all .3s ease",
                }}
              >
                <Icon
                  zIndex="1"
                  as={BsCircleFill}
                  color={activeBullets.userInfo ? "black" : "blue.300"}
                  w={activeBullets.userInfo ? "16px" : "12px"}
                  h={activeBullets.userInfo ? "16px" : "12px"}
                  mb="8px"
                />
                <Text
                  color={activeBullets.userInfo ? "black" : "blue.300"}
                  fontWeight={activeBullets.userInfo ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                  fontSize="sm"
                >
                  1.Rate Plan Info
                </Text>
              </Flex>
            </Tab>
            <Tab
              ref={addressTab}
              _focus={{}}
              w={{ sm: "80px", md: "200px" }}
              isDisabled={isCreate ? !currentAction.address : false}
              onClick={() => {
                if (isCreate) return;
                if (!isEmpty(dirtyFields)) {
                  onOpen();
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: false,
                    profile: false,
                  });
                } else {
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: false,
                    profile: false,
                  });
                  setCurrentAction({
                    userInfo: false,
                    address: true,
                    socials: false,
                    profile: false,
                  });
                }
                setActiveTab(1);
              }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: "80px", md: "200px" },
                  height: "3px",
                  bg: activeBullets.socials ? "black" : "blue.300",
                  left: { sm: "12px", md: "60px" },
                  top: { sm: activeBullets.address ? "6px" : "4px", md: null },
                  position: "absolute",
                  bottom: activeBullets.address ? "40px" : "38px",

                  transition: "all .3s ease",
                }}
              >
                <Icon
                  zIndex="1"
                  as={BsCircleFill}
                  color={activeBullets.address ? "black" : "blue.300"}
                  w={activeBullets.address ? "16px" : "12px"}
                  h={activeBullets.address ? "16px" : "12px"}
                  mb="8px"
                />
                <Text
                  color={activeBullets.address ? "black" : "gray.300"}
                  fontWeight={activeBullets.address ? "bold" : "normal"}
                  transition="all .3s ease"
                  display={{ sm: "none", md: "block" }}
                  fontSize="sm"
                >
                  2.Rate Plan Configs
                </Text>
              </Flex>
            </Tab>
            <Tab
              ref={socialsTab}
              _focus={{}}
              w={{ sm: "80px", md: "200px" }}
              isDisabled={isCreate ? !currentAction.socials : false}
              onClick={() => {
                if (isCreate) return;
                if (!isEmpty(dirtyFields)) {
                  onOpen();
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: true,
                    profile: false,
                  });
                } else {
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: true,
                    profile: false,
                  });
                  setCurrentAction({
                    userInfo: false,
                    address: false,
                    socials: true,
                    profile: false,
                  });
                }
                setActiveTab(2);
              }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: "80px", md: "200px" },
                  height: "3px",
                  bg: activeBullets.profile ? "black" : "blue.300",
                  left: { sm: "12px", md: "74px" },
                  top: { sm: activeBullets.socials ? "6px" : "4px", md: null },
                  position: "absolute",
                  bottom: activeBullets.socials ? "40px" : "38px",
                  transition: "all .3s ease",
                }}
              >
                <Icon
                  zIndex="1"
                  as={BsCircleFill}
                  color={activeBullets.socials ? "black" : "blue.300"}
                  w={activeBullets.socials ? "16px" : "12px"}
                  h={activeBullets.socials ? "16px" : "12px"}
                  mb="8px"
                />
                <Text
                  color={activeBullets.socials ? "black" : "gray.300"}
                  fontWeight={activeBullets.socials ? "bold" : "normal"}
                  transition="all .3s ease"
                  display={{ sm: "none", md: "block" }}
                  fontSize="sm"
                >
                  3.Rate Plan Show Time
                </Text>
              </Flex>
            </Tab>
            <Tab
              ref={profileTab}
              _focus={{}}
              w={{ sm: "80px", md: "200px" }}
              isDisabled={isCreate ? !currentAction.profile : false}
              onClick={() => {
                if (isCreate) return;
                if (!isEmpty(dirtyFields)) {
                  onOpen();
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: true,
                    profile: true,
                  });
                } else {
                  setActiveBullets({
                    userInfo: true,
                    address: true,
                    socials: true,
                    profile: true,
                  });
                  setCurrentAction({
                    userInfo: false,
                    address: false,
                    socials: false,
                    profile: true,
                  });
                }
                setActiveTab(3);
              }}
            >
              <Flex direction="column" justify="center" align="center">
                <Icon
                  zIndex="1"
                  as={BsCircleFill}
                  color={activeBullets.profile ? "black" : "blue.300"}
                  w={activeBullets.profile ? "16px" : "12px"}
                  h={activeBullets.profile ? "16px" : "12px"}
                  mb="8px"
                />
                <Text
                  color={activeBullets.profile ? "black" : "gray.300"}
                  fontWeight={activeBullets.profile ? "bold" : "normal"}
                  transition="all .3s ease"
                  display={{ sm: "none", md: "block" }}
                  fontSize="sm"
                >
                  4.Rate Plan Units
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels maxW={{ md: "90%", lg: "100%" }} mx="auto">
            <TabPanel>
              <Card p={{ sm: 1 }}>
                <CardHeader mb="20px">
                  <Flex direction="column">
                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                      {isCreate
                        ? "ADD RATE PLAN - BASIC INFO"
                        : "EDIT RATE PLAN - BASIC INFO"}
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Flex direction="column" w="100%">
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      templateRows={{ md: "repeat(2, 1fr)" }}
                      gap="12px"
                    >
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Name
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Name"
                          maxLength={250}
                          size="sm"
                          name="name"
                          {...register("name")}
                          isInvalid={errors.name}
                          errorBorderColor="red.300"
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.name?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Name (Vietnamese)
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Name VI"
                          size="sm"
                          name="name_vi"
                          {...register("name_vi")}
                          isInvalid={errors.name_vi}
                          errorBorderColor="red.300"
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.name_vi?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Program Applied
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Program Applied"
                          size="sm"
                          name="program_applied"
                          {...register("program_applied")}
                          isInvalid={errors.program_applied}
                          errorBorderColor="red.300"
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.program_applied?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Program Applied (Vietnamese)
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Program Applied VI"
                          size="sm"
                          name="program_applied_vi"
                          {...register("program_applied_vi")}
                          isInvalid={errors.program_applied_vi}
                          errorBorderColor="red.300"
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.program_applied_vi?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Type
                        </FormLabel>
                        <Controller
                          name="type"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={typeOptions}
                              placeholder="Select Type"
                              size="sm"
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              getOptionLabel={(i) => i.name}
                              getOptionValue={(option) => option.id}
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.type?.message}
                        </Text>
                      </FormControl>
                      <FormControl></FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Show
                        </FormLabel>
                        <Controller
                          name="show_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={listShow}
                              placeholder="Select Show"
                              getOptionLabel={(i) => i.name}
                              size="sm"
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              getOptionValue={(option) => option.id}
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.show_id?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Venue
                        </FormLabel>
                        <Controller
                          name="venue_id"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              options={venueList}
                              placeholder="Select Venue"
                              getOptionLabel={(i) => i.name}
                              getOptionValue={(option) => option.id}
                              size="sm"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.venue_id?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Start Date
                        </FormLabel>
                        <Controller
                          name="from_date"
                          control={control}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              showTimeSelect
                              selected={watch("from_date")}
                              startDate={watch("from_date")}
                              selectsStart
                              endDate={watch("to_date")}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.from_date?.message}
                        </Text>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          To Date
                        </FormLabel>
                        <Controller
                          name="to_date"
                          control={control}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              selected={watch("to_date")}
                              showTimeSelect
                              selectsEnd
                              startDate={watch("from_date")}
                              endDate={watch("to_date")}
                              minDate={watch("from_date")}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.to_date?.message}
                        </Text>
                      </FormControl>
                    </Grid>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Description
                      </FormLabel>
                      <Editor name="description" control={control} />
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Description (Vietnamese)
                      </FormLabel>
                      <Editor name="description_vi" control={control} />
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        name="status_step1"
                        isChecked={status_step1_watch}
                        defaultChecked={true}
                        {...register("status_step1")}
                        size="sm"
                        ms="4px"
                        mt={2}
                      >
                        Active
                      </Switch>
                    </FormControl>
                    <Flex gap="12px" align="flex-end" justify="flex-end">
                      <Button
                        variant="primary"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                        type="submit"
                        onClick={() => setCloseModal(true)}
                      >
                        {isCreate ? "SAVE & EXIT" : "EDIT & EXIT"}
                      </Button>
                      <Button
                        variant="primary"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isSubmitting}
                      >
                        {isCreate ? "SAVE & NEXT" : " EDIT & NEXT"}
                      </Button>
                      <Button
                        variant="dark"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                        onClick={() => {
                          onCloseModal();
                          reset();
                        }}
                      >
                        CANCEL
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card>
                <CardHeader mb="10px">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {isCreate
                      ? "ADD RATE PLAN - CONFIGS"
                      : "EDIT RATE PLAN - CONFIGS"}
                  </Text>
                </CardHeader>
                <CardBody>
                  <Flex mb="30px" gap="40px" flexWrap="wrap">
                    <Flex direction="column" fontSize="small">
                      <Text fontWeight="bold">Code</Text>
                      <Text>{ratePlanInfo?.code}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Name
                      </Text>
                      <Text>{ratePlanInfo?.name}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Program Applied
                      </Text>
                      <Text>{ratePlanInfo?.program_applied}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Type
                      </Text>
                      <Text>{ratePlanInfo?.type}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Show
                      </Text>
                      <Text>{ratePlanInfo?.show}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Venue
                      </Text>
                      <Text>{ratePlanInfo?.venue}</Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="20px">
                      <FormControl
                        sx={{
                          "@media (max-width: 767px)": {
                            display: "none",
                          },
                        }}
                      >
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          <Grid
                            templateColumns={{
                              lg: `${
                                ratePlanInfo?.type === "FAMILY PACKAGE"
                                  ? "1fr 1fr 1fr 1fr 1fr"
                                  : "1fr 1fr 1fr"
                              }`,
                            }}
                            alignItems="center"
                            gap="70px"
                          >
                            <Text>Zone</Text>
                            <Text>
                              {ratePlanInfo?.type === "COMBO"
                                ? "Sale Price"
                                : ratePlanInfo?.type === "PROMOTION"
                                ? null
                                : ratePlanInfo?.type === "VOUCHER"
                                ? "Sale Price"
                                : "Adult Price"}
                            </Text>
                            <Text>
                              {ratePlanInfo?.type === "COMBO"
                                ? "Extra Cost"
                                : ratePlanInfo?.type === "PROMOTION"
                                ? null
                                : ratePlanInfo?.type === "VOUCHER"
                                ? "Prepaid Price"
                                : "Child Price"}
                            </Text>
                            {ratePlanInfo?.type === "FAMILY PACKAGE" && (
                              <Text>Extra Adult Amount</Text>
                            )}
                            {ratePlanInfo?.type === "FAMILY PACKAGE" && (
                              <Text>Extra Child Amount</Text>
                            )}
                          </Grid>
                        </FormLabel>
                      </FormControl>
                      <Flex direction="column" gap="12px">
                        {zoneList && ratePlanInfo?.type !== "VOUCHER" ? (
                          zoneList.map((i, index) => (
                            <>
                              <ZoneItem
                                key={index}
                                type={ratePlanInfo?.type}
                                item={i}
                                register={register}
                                control={control}
                                name="zone_id"
                                disabledItem={
                                  !isCreate && ratePlanItem?.rateplan_configs
                                }
                                isChecked={isCheckZone(i)}
                              />
                              {listSeatingPlanZoneA.length > 0 && index === 0 && (
                                <Flex flexDirection="column">
                                  {(isCreate ||
                                    (!isCreate &&
                                      !ratePlanItem?.rateplan_configs)) && (
                                    <Flex gap={4}>
                                      <Controller
                                        name="seating_plans_zone_1"
                                        control={control}
                                        render={({ field }) => (
                                          <Box maxW="500px" width="100%">
                                            <Select
                                              {...field}
                                              focusBorderColor="blue.500"
                                              chakraStyles={chakraStyles2}
                                              options={listSeatingPlanZoneA}
                                              placeholder="Select Seats Zone A"
                                              getOptionLabel={(i) =>
                                                `${showTextFloor(i.floor)} - ${
                                                  i.seat_number
                                                }`
                                              }
                                              getOptionValue={(option) =>
                                                option.seat_number
                                              }
                                              size="sm"
                                              isMulti
                                              closeMenuOnSelect={false}
                                            />
                                          </Box>
                                        )}
                                      />
                                      <Button
                                        variant="primary"
                                        w="100px"
                                        h="35px"
                                        onClick={() => addSeats("A")}
                                      >
                                        Add Seats
                                      </Button>
                                    </Flex>
                                  )}
                                  <Flex
                                    direction={"column"}
                                    gap={3}
                                    mt={2}
                                    maxH={"400px"}
                                    overflow="auto"
                                  >
                                    {selectedZoneA.map((i, index) => (
                                      <Flex
                                        alignItems="center"
                                        gap={3}
                                        key={index}
                                      >
                                        <Flex fontSize={"sm"}>
                                          {showTextFloor(i.floor)} -{" "}
                                          {i.seat_number}
                                        </Flex>
                                        <Flex>
                                          <Controller
                                            control={control}
                                            name={`adult_amount[${i.floor}-${i.seat_number}]`}
                                            render={({ field }) => (
                                              <NumericFormat
                                                customInput={Input}
                                                thousandSeparator=","
                                                fontSize="sm"
                                                ms="4px"
                                                maxW="400px"
                                                placeholder="Price"
                                                size="sm"
                                                disabled={
                                                  !isCreate &&
                                                  ratePlanItem?.rateplan_configs
                                                }
                                                {...field}
                                              />
                                            )}
                                          />
                                        </Flex>
                                        {isCreate && (
                                          <Icon
                                            onClick={() =>
                                              deleteSeat(i.seat_number, "A")
                                            }
                                            as={MdDelete}
                                            me="0px"
                                            cursor="pointer"
                                          />
                                        )}
                                      </Flex>
                                    ))}
                                  </Flex>
                                </Flex>
                              )}
                              {listSeatingPlanZoneO.length > 0 && index === 1 && (
                                <Flex flexDirection="column">
                                  {(isCreate ||
                                    (!isCreate &&
                                      !ratePlanItem?.rateplan_configs)) && (
                                    <Flex gap={4}>
                                      <Controller
                                        name="seating_plans_zone_2"
                                        control={control}
                                        render={({ field }) => (
                                          <Box maxW="500px" width="100%">
                                            <Select
                                              {...field}
                                              focusBorderColor="blue.500"
                                              chakraStyles={chakraStyles2}
                                              options={listSeatingPlanZoneO}
                                              placeholder="Select Seats Zone O"
                                              getOptionLabel={(i) =>
                                                `${showTextFloor(i.floor)} - ${
                                                  i.seat_number
                                                }`
                                              }
                                              getOptionValue={(option) =>
                                                option.seat_number
                                              }
                                              size="sm"
                                              isMulti
                                              closeMenuOnSelect={false}
                                            />
                                          </Box>
                                        )}
                                      />
                                      <Button
                                        variant="primary"
                                        w="100px"
                                        h="35px"
                                        onClick={() => addSeats("O")}
                                      >
                                        Add Seats
                                      </Button>
                                    </Flex>
                                  )}
                                  <Flex
                                    direction={"column"}
                                    gap={3}
                                    mt={2}
                                    maxH={"400px"}
                                    overflow="auto"
                                  >
                                    {selectedZoneO.map((i, index) => (
                                      <Flex
                                        alignItems="center"
                                        gap={3}
                                        key={index}
                                      >
                                        <Flex fontSize={"sm"}>
                                          {showTextFloor(i.floor)} -{" "}
                                          {i.seat_number}
                                        </Flex>
                                        <Flex>
                                          <Controller
                                            control={control}
                                            name={`adult_amount[${i.floor}-${i.seat_number}]`}
                                            render={({ field }) => (
                                              <NumericFormat
                                                customInput={Input}
                                                thousandSeparator=","
                                                fontSize="sm"
                                                ms="4px"
                                                maxW="400px"
                                                placeholder="Price"
                                                size="sm"
                                                disabled={
                                                  !isCreate &&
                                                  ratePlanItem?.rateplan_configs
                                                }
                                                {...field}
                                              />
                                            )}
                                          />
                                        </Flex>
                                        {isCreate && (
                                          <Icon
                                            onClick={() =>
                                              deleteSeat(i.seat_number, "O")
                                            }
                                            as={MdDelete}
                                            me="0px"
                                            cursor="pointer"
                                          />
                                        )}
                                      </Flex>
                                    ))}
                                  </Flex>
                                </Flex>
                              )}
                              {listSeatingPlanZoneW.length > 0 && index === 2 && (
                                <Flex flexDirection="column">
                                  {(isCreate ||
                                    (!isCreate &&
                                      !ratePlanItem?.rateplan_configs)) && (
                                    <Flex gap={4}>
                                      <Controller
                                        name="seating_plans_zone_3"
                                        control={control}
                                        render={({ field }) => (
                                          <Box maxW="500px" width="100%">
                                            <Select
                                              {...field}
                                              focusBorderColor="blue.500"
                                              chakraStyles={chakraStyles2}
                                              options={listSeatingPlanZoneW}
                                              placeholder="Select Seats Zone W"
                                              getOptionLabel={(i) =>
                                                `${showTextFloor(i.floor)} - ${
                                                  i.seat_number
                                                }`
                                              }
                                              getOptionValue={(option) =>
                                                option.seat_number
                                              }
                                              size="sm"
                                              closeMenuOnSelect={false}
                                              isMulti
                                            />
                                          </Box>
                                        )}
                                      />
                                      <Button
                                        variant="primary"
                                        w="100px"
                                        h="35px"
                                        onClick={() => addSeats("W")}
                                      >
                                        Add Seats
                                      </Button>
                                    </Flex>
                                  )}
                                  <Flex
                                    direction={"column"}
                                    gap={3}
                                    mt={2}
                                    maxH={"400px"}
                                    overflow="auto"
                                  >
                                    {selectedZoneW.map((i, index) => (
                                      <Flex
                                        alignItems="center"
                                        gap={3}
                                        key={index}
                                      >
                                        <Flex fontSize={"sm"}>
                                          {showTextFloor(i.floor)} -{" "}
                                          {i.seat_number}
                                        </Flex>
                                        <Flex>
                                          <Controller
                                            control={control}
                                            name={`adult_amount[${i.floor}-${i.seat_number}]`}
                                            render={({ field }) => (
                                              <NumericFormat
                                                customInput={Input}
                                                thousandSeparator=","
                                                fontSize="sm"
                                                ms="4px"
                                                maxW="400px"
                                                placeholder="Price"
                                                defaultValue={0}
                                                size="sm"
                                                disabled={
                                                  !isCreate &&
                                                  ratePlanItem?.rateplan_configs
                                                }
                                                {...field}
                                              />
                                            )}
                                          />
                                        </Flex>
                                        {isCreate && (
                                          <Icon
                                            onClick={() =>
                                              deleteSeat(i.seat_number, "W")
                                            }
                                            as={MdDelete}
                                            me="0px"
                                            cursor="pointer"
                                          />
                                        )}
                                      </Flex>
                                    ))}
                                  </Flex>
                                </Flex>
                              )}
                            </>
                          ))
                        ) : (
                          <Controller
                            name="zone_id"
                            control={control}
                            defaultValue="no"
                            render={({ field: { onChange, value } }) => (
                              <RadioGroup
                                display="flex"
                                flexDirection={"column"}
                                gap={4}
                                onChange={onChange}
                                value={value}
                              >
                                {zoneList.map((i, index) => (
                                  <>
                                    <ZoneItem
                                      key={index}
                                      type={ratePlanInfo?.type}
                                      item={i}
                                      register={register}
                                      control={control}
                                      name="zone_id"
                                      activeTab="2"
                                      disabledItem={
                                        !isCreate &&
                                        ratePlanItem?.rateplan_configs
                                      }
                                      isChecked={isCheckZone(i)}
                                    />
                                  </>
                                ))}
                              </RadioGroup>
                            )}
                          />
                        )}
                      </Flex>

                      <Grid
                        templateColumns={{
                          sm: "1fr",
                          lg: "repeat(2, 1fr)",
                        }}
                        gap={{base: "16px", lg: "30px"}}
                      >
                        {ratePlanInfo?.type === "FAMILY PACKAGE" && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Package Limit
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Package Limit"
                                size="sm"
                                name="package_limit"
                                {...register("package_limit")}
                                isInvalid={errors.package_limit}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.package_limit?.message}
                              </Text>
                            </FormControl>
                            <FormControl></FormControl>
                          </>
                        )}
                        {ratePlanInfo?.type === "FAMILY PACKAGE" && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Buy Adult
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder=" Buy Adult"
                                size="sm"
                                name="adult"
                                {...register("adult")}
                                isInvalid={errors.adult}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.adult?.message}
                              </Text>
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Buy Child
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Buy Child"
                                size="sm"
                                name="child"
                                {...register("child")}
                                isInvalid={errors.child}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.child?.message}
                              </Text>
                            </FormControl>
                          </>
                        )}
                        {ratePlanInfo?.type === "EARLY BIRD" && (
                          <FormControl>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Before Hours
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Before Hours"
                              size="sm"
                              name="before_hours"
                              {...register("before_hours")}
                              isInvalid={errors.before_hours}
                              errorBorderColor="red.300"
                            />
                            <Text color="red" fontSize={'sm'} mb="10px" ml="5px">
                              {errors.before_hours?.message}
                            </Text>
                          </FormControl>
                        )}
                        {ratePlanInfo?.type === "PROMOTION" && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                From Hours
                              </FormLabel>
                              <Controller
                                name="from_hour"
                                control={control}
                                render={({ field }) => (
                                  <DatePickerInput
                                    onChange={(value) => field.onChange(value)}
                                    selected={field?.value}
                                    showTimeSelectOnly
                                    showTimeSelect
                                    mask="99:99"
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                To Hours
                              </FormLabel>
                              <Controller
                                name="to_hour"
                                control={control}
                                render={({ field }) => (
                                  <DatePickerInput
                                    onChange={(value) => field.onChange(value)}
                                    selected={field?.value}
                                    showTimeSelectOnly
                                    showTimeSelect
                                    mask="99:99"
                                  />
                                )}
                              />
                            </FormControl>
                          </>
                        )}
                        {(ratePlanInfo?.type === "STANDARD" ||
                          ratePlanInfo?.type === "CAMPAIGN" ||
                          ratePlanInfo?.type === "VOUCHER") && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Discount Rate (%)
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Discount Rate (%)"
                                size="sm"
                                name="discount_rate"
                                {...register("discount_rate")}
                                isInvalid={errors.discount_rate}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.discount_rate?.message}
                              </Text>
                            </FormControl>
                            <FormControl></FormControl>
                          </>
                        )}
                        {ratePlanInfo?.type === "CAMPAIGN" && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Campaign Code
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Campaign Code"
                                size="sm"
                                name="campaign_code"
                                {...register("campaign_code")}
                                isInvalid={errors.campaign_code}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.campaign_code?.message}
                              </Text>
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Campaign Medium
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Campaign Medium"
                                size="sm"
                                name="campaign_medium"
                                {...register("campaign_medium")}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 32) {
                                    e.preventDefault();
                                  }
                                }}
                                isInvalid={errors.campaign_medium}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.campaign_medium?.message}
                              </Text>
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Redirect URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Redirect URL"
                                size="sm"
                                name="redirect_url"
                                {...register("redirect_url")}
                                isInvalid={errors.redirect_url}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.redirect_url?.message}
                              </Text>
                            </FormControl>
                            <FormControl></FormControl>
                          </>
                        )}
                        {(ratePlanInfo?.type === "COMBO" ||
                          ratePlanInfo?.type === "STANDARD" ||
                          ratePlanInfo?.type === "CAMPAIGN" ||
                          ratePlanInfo?.type === "VOUCHER") && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Min Applied
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Min Applied"
                                size="sm"
                                name="min"
                                {...register("min")}
                                isInvalid={errors.min}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.min?.message}
                              </Text>
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Max Applied
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Max Applied"
                                size="sm"
                                name="max"
                                {...register("max")}
                                isInvalid={errors.max}
                                errorBorderColor="red.300"
                              />
                              <Text
                                color="red"
                                fontSize={'sm'}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.max?.message}
                              </Text>
                            </FormControl>
                          </>
                        )}
                        {ratePlanInfo?.type === "COMBO" && (
                          <>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Content
                              </FormLabel>
                              <Editor
                                name="content"
                                control={control}
                                defaultValue=""
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Content (Vietnamese)
                              </FormLabel>
                              <Editor
                                name="content_vi"
                                control={control}
                                defaultValue=""
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Combo Description
                              </FormLabel>
                              <Editor
                                name="combo_description"
                                control={control}
                                defaultValue=""
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Combo Description (Vietnamese)
                              </FormLabel>
                              <Editor
                                name="combo_description_vi"
                                control={control}
                                defaultValue=""
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Max Applied"
                                size="sm"
                                name="image"
                                {...register("image")}
                                isInvalid={errors.image}
                                errorBorderColor="red.300"
                              />
                            </FormControl>
                          </>
                        )}
                      </Grid>
                      <Flex>
                        {preViewImage.url && (
                          <Image
                            src={preViewImage.url}
                            width="200px"
                            height="200px"
                            objectFit="contain"
                          />
                        )}
                      </Flex>
                      <FormControl>
                        <Switch
                          id="isChecked"
                          name="status_step2"
                          isChecked={status_step2_watch}
                          defaultChecked={true}
                          {...register("status_step2")}
                          size="sm"
                          ms="4px"
                          mt={2}
                        >
                          Active
                        </Switch>
                      </FormControl>
                    </Stack>
                    <Flex justify="flex-end">
                      <Button
                        variant="primary"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        NEXT
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card>
                <CardHeader mb="40px">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {isCreate
                      ? "ADD RATE PLAN SHOWING TIME"
                      : "EDIT RATE PLAN SHOWING TIME"}
                  </Text>
                </CardHeader>
                <CardBody>
                  <Flex mb="30px" gap="40px" flexWrap={"wrap"}>
                    <Flex direction="column" fontSize="small">
                      <Text fontWeight="bold">Code</Text>
                      <Text>{ratePlanInfo?.code}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Name
                      </Text>
                      <Text>{ratePlanInfo?.name}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Program Applied
                      </Text>
                      <Text>{ratePlanInfo?.program_applied}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Type
                      </Text>
                      <Text>{ratePlanInfo?.type}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Show
                      </Text>
                      <Text>{ratePlanInfo?.show}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Venue
                      </Text>
                      <Text>{ratePlanInfo?.venue}</Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column" w="100%">
                    <Grid
                      mb={4}
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      gap="12px"
                    >
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Show Date From
                        </FormLabel>
                        <Controller
                          name="from_showdate"
                          control={control}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              selected={watch("from_showdate")}
                              showTimeSelect
                              startDate={watch("from_showdate")}
                              selectsStart
                              endDate={watch("to_showdate")}
                            />
                          )}
                        />
                        <Text color="red" fontSize={'sm'} mb="10px" ml="5px">
                          {errors.from_showdate?.message}
                        </Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Show Date To
                        </FormLabel>
                        <Controller
                          name="to_showdate"
                          control={control}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              selected={watch("to_showdate")}
                              showTimeSelect
                              selectsEnd
                              startDate={watch("from_showdate")}
                              endDate={watch("to_showdate")}
                              minDate={watch("from_showdate")}
                            />
                          )}
                        />
                        <Text color="red" fontSize={'sm'} mb="10px" ml="5px">
                          {errors.to_showdate?.message}
                        </Text>
                      </FormControl>
                    </Grid>
                    <Flex maxH={"300px"} overflowY={"auto"}>
                      <Stack direction="column" spacing={4}>
                        <Flex direction="column">
                          Apply for Specific shows
                          {from_showdate && (
                            <Flex mt="5px" ml="15px" gap={30}>
                              {Object.entries(groupedShowData).map(
                                ([key, value], index) => (
                                  <Flex direction="column">
                                    <Flex key={index}>
                                      <Checkbox
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            checkedAllShowTime(value, key);
                                          } else {
                                            removeShowTime(value, key);
                                          }
                                        }}
                                        size="md"
                                        isChecked={timeCheckedAll.includes(key)}
                                      />
                                      <Text ml={2}>{key}</Text>
                                    </Flex>
                                    <Controller
                                      name="excludes"
                                      control={control}
                                      render={({ field }) => (
                                        <CheckboxGroup {...field}>
                                          {value.map((i, index) => (
                                            <Flex key={index}>
                                              <Checkbox
                                                value={i.id.toString()}
                                                size="md"
                                              />
                                              <Text ml="10px">
                                                {i.show.name} -{" "}
                                                {moment(i.showdate).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </Text>
                                            </Flex>
                                          ))}
                                        </CheckboxGroup>
                                      )}
                                    />
                                  </Flex>
                                )
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </Stack>
                    </Flex>
                    <Flex justify="flex-end">
                      <Button
                        variant="primary"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        NEXT
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card>
                <CardHeader mb="40px">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {isCreate ? "ADD RATE PLAN UNITS" : "EDIT RATE PLAN UNITS"}
                  </Text>
                </CardHeader>
                <CardBody>
                  <Flex mb="30px" gap={"40px"} flexWrap={"wrap"}>
                    <Flex direction="column" fontSize="small">
                      <Text fontWeight="bold">Code</Text>
                      <Text>{ratePlanInfo?.code}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Name
                      </Text>
                      <Text>{ratePlanInfo?.name}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Program Applied
                      </Text>
                      <Text>{ratePlanInfo?.program_applied}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Type
                      </Text>
                      <Text>{ratePlanInfo?.type}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Show
                      </Text>
                      <Text>{ratePlanInfo?.show}</Text>
                    </Flex>
                    <Flex direction="column" fontSize="small">
                      <Text fontSize="sm" fontWeight="bold">
                        Venue
                      </Text>
                      <Text>{ratePlanInfo?.venue}</Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column" gap="20px">
                    <Tabs
                      isLazy
                      defaultIndex={isCreate ? 1 : 0}
                      isFitted
                      size="sm"
                    >
                      <TabList mb="1em">
                        <Tab _focus={{}} isDisabled={isCreate && !isCopy}>
                          View Units
                        </Tab>
                        <Tab _focus={{}}>Add Units</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel p={0}>
                          <Modal
                            isOpen={isOpenConfirm}
                            onClose={() => onCloseConfirm()}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Confirm Action</ModalHeader>
                              <ModalBody>
                                Are you sure you want to perform this action?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  variant="ghost"
                                  onClick={() => onCloseConfirm()}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  colorScheme="red"
                                  ml={3}
                                  onClick={() => confirmSwitchAll()}
                                >
                                  Confirm
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <Card>
                            <form id="unit-user-search-form">
                              <Flex
                                direction="row"
                                alignItems="center"
                                mb={3}
                                gap={4}
                                flexWrap={"wrap"}
                              >
                                <Box
                                  direction="row"
                                  alignItems="center"
                                  display="block"
                                >
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    ms="4px"
                                  >
                                    Channel
                                  </FormLabel>
                                  <Controller
                                    name="channelSearch"
                                    control={controlSearch}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        focusBorderColor="blue.500"
                                        chakraStyles={chakraStyles}
                                        options={listChannel}
                                        placeholder="Select Channel"
                                        getOptionLabel={(i) => i.name}
                                        getOptionValue={(option) => option.id}
                                        size="sm"
                                        closeMenuOnSelect={true}
                                      />
                                    )}
                                  />
                                </Box>
                                {channelSearch && (
                                  <Box
                                    direction="row"
                                    alignItems="center"
                                    display="block"
                                  >
                                    <FormLabel
                                      ms="4px"
                                      fontSize="xs"
                                      fontWeight="semibold"
                                    >
                                      Unit
                                    </FormLabel>
                                    <Controller
                                      name="unitSearch"
                                      control={controlSearch}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          focusBorderColor="blue.500"
                                          chakraStyles={chakraStyles}
                                          options={listUnitView}
                                          placeholder="Select Unit"
                                          getOptionLabel={(i) => i.name}
                                          getOptionValue={(option) => option.id}
                                          size="sm"
                                          closeMenuOnSelect={false}
                                          isMulti
                                        />
                                      )}
                                    />
                                  </Box>
                                )}
                              </Flex>
                              <Flex
                                justify="center"
                                align="center"
                                gap="8px"
                                mt={3}
                              >
                                <Box
                                  direction="row"
                                  alignItems="center"
                                  display="block"
                                >
                                  <Button
                                    leftIcon={<RxReset />}
                                    variant="primary"
                                    w="88px"
                                    h="33px"
                                    onClick={() => {
                                      resetSearch();
                                      setValueSearch("channelSearch", null);
                                      setValueSearch("unitSearch", null);
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Box>
                                <Box
                                  direction="row"
                                  alignItems="center"
                                  display="block"
                                >
                                  <Button
                                    leftIcon={<BsSearch />}
                                    variant="primary"
                                    w="88px"
                                    h="33px"
                                    onClick={() => onSubmitSearch()}
                                  >
                                    Search
                                  </Button>
                                </Box>
                              </Flex>
                            </form>
                          </Card>
                          {listCheckedRateplanUnitChoose.length > 1 && (
                            <Flex gap={2} mb={4}>
                              <Button
                                onClick={() => {
                                  onOpenConfirm();
                                  setIsActiveAll(true);
                                }}
                                variant="primary"
                                mt="24px"
                                h="35px"
                              >
                                Activate selected Units
                              </Button>
                              <Button
                                onClick={() => {
                                  onOpenConfirm();
                                  setIsActiveAll(false);
                                }}
                                variant="primary"
                                mt="24px"
                                ml="24px"
                                h="35px"
                              >
                                Deactivate selected Units
                              </Button>
                            </Flex>
                          )}
                          <SearchTable2
                            tableData={newRatePlansUnits}
                            columnsData={columnsViewDataRatePlanUnits}
                            totalRow={totalRows?.ratePlanUnits}
                            listPermissions={[33]}
                            enableRowSelection
                            enableRowActions={false}
                            updateCheckbox={(val) => getValueChecked2(val)}
                            loadData={(fetchDataOptions) =>
                              recallApi2(fetchDataOptions)
                            }
                            showColumnFilters={false}
                            clearAllCheckBox={clearAllCheckBox}
                          />
                        </TabPanel>
                        <TabPanel p={0}>
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                            sx={{
                              "@media (max-width: 767px)": {
                                display: "none",
                              },
                            }}
                          >
                            <Grid
                              templateColumns={{
                                lg: "1fr 1fr 1fr",
                              }}
                              alignItems="flex-start"
                              gap="40px"
                            >
                              <Text>Zone</Text>
                              <Text>Commission1</Text>
                              <Text>Commission2</Text>
                            </Grid>
                          </FormLabel>
                          <Flex direction="column" gap="24px" mb={4}>
                            {zoneList
                              .filter(
                                (item) =>
                                  zone_id &&
                                  zone_id?.includes(item.id.toString())
                              )
                              .map((i, index) => (
                                <ZoneItem
                                  control={control}
                                  key={index}
                                  type={ratePlanInfo?.type}
                                  item={i}
                                  register={register}
                                  name="commissions"
                                  isChecked={
                                    commissions &&
                                    commissions.includes(i.id.toString())
                                  }
                                />
                              ))}
                          </Flex>
                          <FormControl mb={2}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Channel
                            </FormLabel>
                            <Controller
                              name="channel"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={listChannel}
                                  placeholder="Select Channel"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                />
                              )}
                            />
                          </FormControl>

                          {listUnitChoosed.length > 0 && (
                            <Flex direction="column">
                              <Text fontWeight="bold" mb={2}>
                                Units Choosed
                              </Text>
                              <Flex
                                direction="column"
                                maxH="150px"
                                overflowY="auto"
                              >
                                {listUnitChoosed.map((i, index) => (
                                  <Text key={index} color={"green.600"} mb={2}>
                                    {i.unit_name}
                                  </Text>
                                ))}
                              </Flex>
                            </Flex>
                          )}
                          <Flex alignItems="flex-end" gap={4}>
                            <Flex direction="column">
                              <FormLabel ms="4px" fontSize="sm">
                                Unit Name
                              </FormLabel>
                              <Input
                                _placeholder={{ color: "gray.400" }}
                                variant="main"
                                type="text"
                                placeholder="Unit Name"
                                minW="80px"
                                maxW="185px"
                                size="sm"
                                fontSize="sm"
                                name="unitSearchAdd"
                                {...register("unitSearchAdd")}
                                _focus={{ borderColor: "blue.500" }}
                              />
                            </Flex>
                            <Button
                              leftIcon={<BsSearch />}
                              variant="primary"
                              w="88px"
                              h="33px"
                              onClick={() => searchUnitByName()}
                            >
                              Search
                            </Button>
                          </Flex>

                          <Flex gap={5} mb={3}>
                            {listUnit.length > 0 && (
                              <Button
                                onClick={(e) => confirmAdd(e, "confirmAll")}
                                variant="primary"
                                mt="24px"
                                w="100px"
                                h="35px"
                              >
                                Confirm Add All
                              </Button>
                            )}

                            {listCheckedChoose.length > 0 && (
                              <>
                                <Button
                                  onClick={(e) => confirmAdd(e)}
                                  variant="primary"
                                  mt="24px"
                                  w="100px"
                                  h="35px"
                                >
                                  Confirm Add
                                </Button>
                              </>
                            )}
                          </Flex>

                          {listUnit.length > 0 ? (
                            <Flex>
                              <SearchTable2
                                tableData={listUnit}
                                columnsData={columnsDataRatePlanUnits}
                                totalRow={totalRows.units}
                                enableRowSelection
                                updateCheckbox={(val) => getValueChecked(val)}
                                isFetching={isFetching}
                                loadData={(fetchDataOptions) =>
                                  recallApi(fetchDataOptions)
                                }
                                enableRowActions={false}
                                showColumnFilters={false}
                              />
                            </Flex>
                          ) : (
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              fontSize="md"
                              fontWeight="bold"
                            >
                              No Record
                            </Flex>
                          )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Flex>
                  <Flex direction="column" w="100%">
                    <Flex justify="flex-end">
                      <Button
                        type="submit"
                        variant="primary"
                        mt="24px"
                        w="100px"
                        h="35px"
                        isLoading={isSubmitting}
                      >
                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                          DONE
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Modal isOpen={isOpen} onClose={() => handleCancel()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Action</ModalHeader>
            <ModalBody>Unsaved changes detected!</ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={handleCancelConfirm}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={() => handleConfirm()}>
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </Flex>
  );
}

export default NewRatePlan;
