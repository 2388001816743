import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Image,
  Link,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { SidebarContext } from 'contexts/SidebarContext';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import AdminNavbarLinks from './AdminNavbarLinks';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import logo from 'assets/img/logo.png';
import SearchInput from 'components/SeachInput/SearchInput';

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const parts = location.pathname.split('/');
  const {
    sidebarWidth,
    setSidebarWidth,
    toggleSidebar,
    setToggleSidebar,
  } = useContext(SidebarContext);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    onOpen,
    path,
    ...rest
  } = props;
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText =
    fixed && scrolled
      ? useColorModeValue('gray.700', 'gray.200')
      : useColorModeValue('gray.700', 'gray.200');
  let secondaryText =
    fixed && scrolled
      ? useColorModeValue('gray.700', 'gray.200')
      : useColorModeValue('gray.700', 'gray.200');
  let navbarPosition = 'static';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = 'none';
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '10px';
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = 'fixed';
      navbarShadow = useColorModeValue(
        '0px 7px 23px rgba(0, 0, 0, 0.05)',
        'none'
      );
      navbarBg = useColorModeValue(
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)',
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
      );
      navbarBorder = useColorModeValue('#FFFFFF', 'rgba(255, 255, 255, 0.31)');
      navbarFilter = useColorModeValue(
        'none',
        'drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))'
      );
    }
  if (props.secondary) {
    navbarBackdrop = 'none';
    navbarPosition = 'absolute';
    mainText = 'white';
    secondaryText = 'white';
    secondaryMargin = '22px';
    paddingX = '30px';
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Flex
      id='header'
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      borderWidth='1.5px'
      borderStyle='solid'
      // transitionDelay="0s, 0s, 0s, 0s"
      // transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      // transition-property="box-shadow, background-color, filter, border"
      // transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      borderRadius='16px'
      display={
        location.pathname === '/admin/booking/bookingscan' && isMobile
          ? 'none'
          : 'flex'
      }
      h='64px'
      justifyContent={{ xl: 'center' }}
      mt={secondaryMargin}
    >
      <Container
        maxW={{ md: '90%', base: '100%' }}
        px={{
          base: paddingX,
          md: '10px',
        }}
      >
        <Flex
          w='100%'
          flexDirection={{
            sm: 'row',
            md: 'row',
          }}
          justifyContent={'space-between'}
          alignItems={{ sm: 'center' }}
        >
          <div>
            <Image
              boxSize='64px'
              objectFit='contain'
              src={logo}
              alt='logo'
              sx={{
                position: 'relative',
                top: '5px',
              }}
            />
            <HamburgerIcon
              w='50px'
              h='20px'
              color='gray.700'
              cursor='pointer'
              display={{ sm: 'none', xl: 'block' }}
              onClick={() => {
                setSidebarWidth(sidebarWidth === 275 ? 120 : 275);
                setToggleSidebar(!toggleSidebar);
              }}
              sx={{
                '@media (min-width: 1200px)': {
                  display: 'none',
                },
              }}
            />
            <Box mb={{ sm: '8px', md: '0px' }}>
              <Breadcrumb display={{ sm: 'none', lg: 'none' }} fontSize={'var(--chakra-fontSizes-sm)'}>
                <BreadcrumbItem color={mainText}>
                  <BreadcrumbLink href={`#/admin${path}`} color={secondaryText}>
                    {parts.slice(2, 3)[0].charAt(0).toUpperCase() +
                      parts.slice(2, 3)[0].slice(1)}
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem color={mainText}>
                  <BreadcrumbLink color={mainText}>{brandText}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              {/* Here we create navbar brand, based on route name */}
              <Link
                color={mainText}
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                _hover={{ color: { mainText } }}
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
                display={'none'}
              >
                {brandText}
              </Link>
            </Box>
          </div>
          {!isMobile && (
            <div>
              <SearchInput />
            </div>
          )}
          <div>
            <Box ms='auto'>
              <AdminNavbarLinks
                onOpen={props.onOpen}
                logoText={props.logoText}
                secondary={props.secondary}
                fixed={props.fixed}
                scrolled={scrolled}
                onOpenSearch={props.onOpenSearch}
              />
            </Box>
          </div>
        </Flex>
      </Container>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
