import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Text,
  Grid,
  Table,
  Tbody,
  useDisclosure,
  Th,
  Thead,
  Tr,
  RadioGroup,
  Radio,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  Portal,
  Box,
  Select,
  Textarea,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import {
  listChannelAtom,
  listUnitAtom,
  paymentStatusListAtom,
} from 'state/show';
import { useRecoilValue } from 'recoil';
import { Select as ReSelect } from 'chakra-react-select';
import { HSeparator } from 'components/Separator/Separator';
import { useBookingActions } from 'hooks/useBookingActions';
import TablesTableRow from 'components/Tables/TablesTableRow';
import { QrCodeIConMobile } from 'components/Icons/Icons';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useRatePlanActions } from 'hooks/useRatePlanActions';
import { selectedShowAtom } from 'state/show';
import RatePlanCard from 'components/RatePlanCard/RatePlanCard';
import CountDown from 'components/CountDown/CountDown';
import ComboCard from 'components/ComboCard/ComboCard';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { showTextFloor } from 'variables/columnsData';
import { NumericFormat } from 'react-number-format';
import ReactDatePicker from 'react-datepicker';

function BookingPayment() {
  const textColor = useColorModeValue('gray.700', 'white');
  const listChannel = useRecoilValue(listChannelAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const selectedShow = useRecoilValue(selectedShowAtom);
  const bookingActions = useBookingActions();
  const ratePlanActions = useRatePlanActions();
  const [bookingInfo, setBookingInfo] = useState();
  const [listRatePlan, setListRatePlan] = useState([]);
  const [listCombo, setListCombo] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [depositValue, setDepositValue] = useState(0);
  const [totalRemain, setTotalRemain] = useState('');
  const [selectedRatePlan, setSelectedRatePlan] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedCombo, setSelectedCombo] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const listPaymentStatus = useRecoilValue(paymentStatusListAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const validationSchema = Yup.object().shape({});
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState,
    reset,
    control,
    watch,
    setError,
  } = useForm(formOptions);
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const channel = watch('channel');
  const unit = watch('unit');
  const deposit = watch('deposit');
  const paymentStatus = watch('payment_status');
  const paymentMethodWatch = watch('payment_method');
  const voucher_code = watch('promotion_code');
  console.log(paymentStatus, 'paymentStatus');
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: '20px',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '150px',
    }),
  };

  useEffect(async () => {
    await bookingActions.getListChannel(
      {
        status: 1,
      },
      0,
      ''
    );
    await bookingActions.getBookingInfo();
    await bookingActions.getListPaymentStatus({ status: 1 }, 0, '');
    const {
      data: { data: bookingData },
    } = await bookingActions.getBookingInfo();
    setValue('payment_status', 3);
    setBookingInfo(bookingData);
    const total = bookingData.calculator.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(total);
    setTotalRemain(total);
    setValue('validity', '');
  }, []);

  useEffect(async () => {
    if (paymentStatus) {
      if (parseInt(paymentStatus) === 1) {
        const data = await bookingActions.getListPaymentMethod(
          { status: 1 },
          0,
          ''
        );
        setPaymentMethod(data);
      } else if (parseInt(paymentStatus) === 3) {
        setPaymentMethod([]);
      } else {
        setPaymentMethod([]);
      }
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (channel) {
      setValue('unit', null);
      setSelectedRatePlan([]);
      bookingActions.getListUnits({ channel_id: channel.id, status: 1 }, 0, '');
    }
  }, [channel]);

  useEffect(async () => {
    if (unit) {
      setIsLoaded(true);
      setSelectedRatePlan([]);
      try {
        await bookingActions.selectUnit({ unit_id: unit.id });
        const {
          data: { data: bookingData },
        } = await bookingActions.getBookingInfo();
        const {
          data: { data: ratePlanData },
        } = await ratePlanActions.getListRatePlansByShowTime({
          show_schedule_detail_id: selectedShow.booking.show_schedule_detail_id,
          unit_id: unit.id,
          zone_id: bookingData.seats[0].zone.id,
          child: bookingData.child,
          adult: bookingData.adult,
        });
        const {
          data: { data: listComboData },
        } = await ratePlanActions.getListComboByShowTime({
          show_schedule_detail_id: selectedShow.booking.show_schedule_detail_id,
          zone_id: bookingData.seats[0].zone.id,
          unit_id: unit.id,
          child: bookingData.child,
          adult: bookingData.adult,
        });
        const total = bookingData.calculator.reduce(
          (acc, curr) => acc + curr.amount,
          0
        );
        setListRatePlan(ratePlanData);
        setListCombo(listComboData);
        setTotalAmount(total);
        setTotalRemain(total);
        setBookingInfo(bookingData);
      } catch (error) {
        toast({
          status: 'error',
          description: `Something wrong: ${error}`,
          position: 'top-right',
          duration: 5000,
        });
        setIsLoaded(false);
      }
      setIsLoaded(false);
      if (unit?.is_liability === 1) {
        setValue('payment_status', 2);
      }
    }
  }, [unit]);

  useEffect(async () => {
    if (deposit === 'yes') {
      const data = await bookingActions.getListPaymentMethod(
        { status: 1 },
        0,
        ''
      );
      setPaymentMethod(data);
      setValue('payment_status', 1);
    } else {
      setTotalRemain(totalAmount);
      setDepositValue(0);
    }
  }, [deposit]);

  useEffect(() => {
    if (depositValue) {
      const remain = parseInt(totalAmount) - depositValue;
      setTotalRemain(remain);
    }
  }, [depositValue]);

  // useEffect(() => {
  //   if(listUnit && listUnit.length > 0){
  //     console.log(listUnit,'listUnit')
  //     setValue("channel", listUnit[0].channel)
  //     // setListChannel(listUnit[0].channel)
  //   }
  // },[listUnit])

  useEffect(() => {
    if (paymentMethodWatch === 7) {
      setValue('payment_status', 1);
    }
  }, [paymentMethodWatch]);

  const scanQRCode = async (val) => {
    setValue('promotion_code', val);
    onClose();
    try {
      const res = await bookingActions.selectVoucher({
        voucher_code: val,
      });
      if (res.status === 200) {
        toast({
          status: 'success',
          description: 'Apply promotion code successful',
          position: 'top-right',
          duration: 2000,
        });
        updateBookingInfo();
      }
    } catch (error) {
      toast({
        status: 'error',
        description: `${error.response.data.meta.message}`,
        position: 'top-right',
        duration: 2000,
      });
      console.warn(error);
    }
  };

  function disableCamera() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        // Get all video tracks from the stream
        const videoTracks = stream.getVideoTracks();

        // Disable each video track
        videoTracks.forEach(function (track) {
          track.enabled = false;
        });

        console.log('Camera disabled');
      })
      .catch(function (error) {
        console.log('Error disabling camera:', error);
      });
  }

  const resetRatePlan = async () => {
    await bookingActions.selectUnit({ unit_id: unit.id });
    const {
      data: { data: bookingData },
    } = await bookingActions.getBookingInfo();
    setBookingInfo(bookingData);
    setSelectedRatePlan([]);
    setSelectedCombo([]);
  };

  const applyVoucher = async () => {
    try {
      const res = await bookingActions.selectVoucher({
        voucher_code: voucher_code,
      });
      if (res.status === 200) {
        toast({
          status: 'success',
          description: 'Apply promotion code successful',
          position: 'top-right',
          duration: 2000,
        });
        updateBookingInfo();
      }
    } catch (error) {
      toast({
        status: 'error',
        description: `${error.response.data.meta.message}`,
        position: 'top-right',
        duration: 2000,
      });
      console.warn(error);
    }
  };

  const searchUnit = async () => {
    const unitCode = watch('unit_code')?.replace(/\s/g, '');
    const res = await bookingActions.getListUnits(
      { code: unitCode, status: 1 },
      0,
      ''
    );
    setValue('channel', res.data.data[0].channel);
    setValue('unit', res.data.data[0]);
  };

  const onSelectedRatePlan = async (val) => {
    try {
      const res = await bookingActions.selectRatePlan({
        rateplan_detail_id: val,
      });
      let ratePlanSelected = [];
      let currentValue = [...selectedRatePlan, val];
      if (res.data.data.rateplan_remove.length > 0) {
        ratePlanSelected = currentValue.filter(
          (value) => !res.data.data.rateplan_remove.includes(value)
        );
      } else {
        ratePlanSelected = currentValue;
      }
      if (selectedRatePlan.includes(val)) {
        setSelectedRatePlan((prevState) =>
          prevState.filter((ratePlan) => ratePlan !== val)
        );
      } else {
        setSelectedRatePlan(ratePlanSelected);
      }
      currentValue = [];
      if (res.status === 200) {
        updateBookingInfo();
      }
    } catch (error) {
      toast({
        description: `${error?.response?.data?.meta?.message}`,
        status: 'error',
        position: 'top-right',
        duration: 2000,
      });
    }
  };

  const updateBookingInfo = async () => {
    const {
      data: { data: bookingData },
    } = await bookingActions.getBookingInfo();
    const total = bookingData.calculator.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setTotalAmount(total);
    setTotalRemain(total);
    setBookingInfo(bookingData);
    if (parseInt(total) === 0) {
      setValue('payment_status', 1);
      setValue('payment_method', 7);
    }
    if (parseInt(total) !== 0 && unit?.is_liability === 2) {
      setValue('payment_status', 3);
    } else if (unit?.is_liability === 1 && parseInt(total) !== 0) {
      setValue('payment_status', 2);
    }
  };

  const onSelectedCombo = async (val) => {
    if (selectedCombo === val.id && !val.updateQuantity) return;
    setSelectedCombo(val.id);
    const res = await bookingActions.selectCombo({
      rateplan_detail_id: val.id,
      quantity: val.quantity,
    });
    let ratePlanSelected = [];
    let currentValue = [...selectedRatePlan];
    if (res.data.data.rateplan_remove.length > 0) {
      ratePlanSelected = currentValue.filter(
        (value) => !res.data.data.rateplan_remove.includes(value)
      );
    } else {
      ratePlanSelected = currentValue;
    }
    if (selectedRatePlan.includes(val)) {
      setSelectedRatePlan((prevState) =>
        prevState.filter((ratePlan) => ratePlan !== val)
      );
    } else {
      setSelectedRatePlan(ratePlanSelected);
    }
    currentValue = [];
    if (res.status === 200) {
      updateBookingInfo();
    }
  };

  const { errors, isSubmitting } = formState;
  async function onSubmit({ notes, validity, payment_status, payment_method }) {
    if (voucher_code && !unit) {
      return;
    }
    if (!unit) {
      setError('unit', { message: 'Please select Unit for this booking' });
      return;
    }
    const checkSelectedRatePlan = bookingInfo?.calculator?.every(
      (i) => i.program_apply
    );
    if (!checkSelectedRatePlan && !voucher_code) {
      setError('rateplan', {
        message: 'Please select Rate Plan for this booking',
      });
      return;
    }
    // if (payment_status == 3 && (!validity || validity.length === 0)) {
    //   setError("validity", {
    //     message: "Please select validity for this booking",
    //   });
    //   return;
    // }
    let params = {
      notes,
      deposit: depositValue || 0,
      payment_status: parseInt(payment_status),
    };
    if (parseInt(payment_status) === 1) {
      params.payment_method = parseInt(payment_method);
    }
    if (validity) {
      params.validity = validity.toISOString();
    }
    try {
      const res = await bookingActions.createBooking(params);
      if (res.status === 200) {
        toast({
          description: 'Well done',
          duration: 2000,
          status: 'success',
        });
        history.push(`/admin/booking/create/detail/${res.data.data.id}`);
      }
    } catch (error) {
      toast({
        description: `${error?.response?.data?.meta?.message}`,
        status: 'error',
      });
    }
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    let showDate = new Date(bookingInfo?.show_date);
    let [hours, minutes, seconds] = bookingInfo?.show_time
      .split(':')
      .map(Number);
    showDate.setHours(hours);
    showDate.setMinutes(minutes);
    showDate.setSeconds(seconds);
    return (
      new Date(time).getTime() < showDate.getTime() &&
      currentDate.getTime() < selectedDate.getTime()
    );
  };

  const filterDate = (date) => {
    let showDate = new Date(bookingInfo?.show_date);
    showDate.setHours(0, 0, 0, 0);

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let targetDate = new Date(date);
    targetDate.setHours(0, 0, 0, 0);

    return targetDate >= currentDate && targetDate <= showDate;
  };

  const isRequiredValidity = () => {
    return paymentStatus === 3;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        <Card>
          <CardBody>
            <Stack direction='column' spacing='20px' w='100%'>
              <Flex
                direction={{ sm: 'column', md: 'row' }}
                gap={{ sm: '10px', lg: '30px' }}
                w={{ lg: '50%', md: '100%' }}
                flexWrap='wrap'
              >
                <FormControl w='300px' flex='0 0 auto' maxW='100%'>
                  <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                    Channel
                  </FormLabel>
                  <Controller
                    name='channel'
                    control={control}
                    render={({ field }) => (
                      <ReSelect
                        {...field}
                        focusBorderColor='blue.500'
                        chakraStyles={chakraStyles}
                        options={listChannel}
                        placeholder='Select Channel'
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size='sm'
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                </FormControl>
                <FormControl w='300px' flex='0 0 auto' maxW='100%'>
                  <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                    Unit Code
                  </FormLabel>
                  <Flex align='center'>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Unit Code'
                      size='sm'
                      name='unit_code'
                      {...register('unit_code')}
                      isInvalid={errors.unit_code}
                      errorBorderColor='red.300'
                      maxW='200px'
                    />
                    <Button
                      size='sm'
                      minW='110px'
                      colorScheme='blue'
                      ml={{ lg: 4, sm: 1 }}
                      fontSize='base'
                      onClick={() => searchUnit()}
                    >
                      Search Unit
                    </Button>
                  </Flex>
                </FormControl>

                <FormControl isRequired w='300px' flex='0 0 auto' maxW='100%'>
                  <Box>
                    <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                      Unit
                    </FormLabel>
                    <Controller
                      name='unit'
                      control={control}
                      render={({ field }) => (
                        <ReSelect
                          {...field}
                          focusBorderColor='blue.500'
                          chakraStyles={chakraStyles}
                          options={listUnit}
                          placeholder='Select Unit'
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size='sm'
                          closeMenuOnSelect={true}
                        />
                      )}
                    />
                    <Text color='red' fontSize={14} mb='10px' ml='5px'>
                      {errors.unit?.message}
                    </Text>
                  </Box>
                </FormControl>
              </Flex>
              <Flex direction='column'>
                {isLoaded ? (
                  <Flex direction='column' mt='32px' gap={4}>
                    <FormLabel fontSize='md' fontWeight='bold' mb='10px'>
                      Rate Plan
                    </FormLabel>
                    <Grid
                      templateColumns={{
                        sm: '1fr',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(4, 1fr)',
                      }}
                      gap='30px'
                      cursor='pointer'
                    >
                      <Skeleton height='188px' fadeDuration={1}></Skeleton>
                      <Skeleton height='188px' fadeDuration={1}></Skeleton>
                      <Skeleton height='188px' fadeDuration={1}></Skeleton>
                      <Skeleton height='188px' fadeDuration={1}></Skeleton>
                    </Grid>
                  </Flex>
                ) : (
                  <Flex direction='column' mt='32px' gap={2}>
                    <Flex>
                      <FormLabel fontSize='md' fontWeight='bold'>
                        Rate Plan
                      </FormLabel>
                    </Flex>
                    <Flex>
                      <Text color='red' fontSize='sm'>
                        {errors.rateplan?.message}
                      </Text>
                    </Flex>

                    {listRatePlan.length > 0 && (
                      <Grid
                        templateColumns={{
                          sm: '1fr',
                          md: 'repeat(2, 1fr)',
                          lg: 'repeat(4, 1fr)',
                        }}
                        gap='30px'
                        cursor='pointer'
                      >
                        {listRatePlan.map((i, index) => (
                          <RatePlanCard
                            key={index}
                            item={i}
                            bookingInfo={bookingInfo}
                            selectedRatePlan={selectedRatePlan}
                            onSelect={(val) => onSelectedRatePlan(val)}
                          />
                        ))}
                      </Grid>
                    )}
                  </Flex>
                )}
                {isLoaded ? (
                  <Flex direction='column' mt='32px' gap={4}>
                    <FormLabel fontSize='md' fontWeight='bold'>
                      Combo
                    </FormLabel>
                    <Grid
                      templateColumns={{
                        sm: '1fr',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(4, 1fr)',
                      }}
                      gap='30px'
                      cursor='pointer'
                    >
                      <Skeleton height='124px' fadeDuration={1}></Skeleton>
                      <Skeleton height='124px' fadeDuration={1}></Skeleton>
                      <Skeleton height='124px' fadeDuration={1}></Skeleton>
                      <Skeleton height='124px' fadeDuration={1}></Skeleton>
                    </Grid>
                  </Flex>
                ) : (
                  listCombo.length > 0 && (
                    <Flex direction='column' mt='32px' gap={2}>
                      <Flex>
                        <FormLabel fontSize='md' fontWeight='bold' mb='10px'>
                          Combo
                        </FormLabel>
                      </Flex>
                      <Grid
                        templateColumns={{
                          sm: '1fr',
                          md: 'repeat(2, 1fr)',
                          lg: 'repeat(4, 1fr)',
                        }}
                        gap='30px'
                        cursor='pointer'
                      >
                        {listCombo.map((i, index) => (
                          <ComboCard
                            bookingInfo={bookingInfo}
                            key={index}
                            item={i}
                            isSelected={
                              i.rateplan_configs.length > 0
                                ? i?.rateplan_configs[0]?.id === selectedCombo
                                : false
                            }
                            onSelect={(val) => onSelectedCombo(val)}
                          />
                        ))}
                      </Grid>
                    </Flex>
                  )
                )}
              </Flex>
              <Stack
                maxW={{ lg: '50%', sm: '100%' }}
                direction={{ sm: 'column', md: 'row' }}
                spacing='30px'
              >
                <FormControl>
                  <FormLabel fontSize='xs' fontWeight='bold' mb='10px'>
                    Promotion Code
                  </FormLabel>
                  <Flex align='center'>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      type='text'
                      placeholder='Promotion Code'
                      size='sm'
                      name='promotion_code'
                      {...register('promotion_code')}
                      isInvalid={errors.promotion_code}
                      errorBorderColor='red.300'
                      maxW='200px'
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          applyVoucher();
                        }
                      }}
                    />
                    <QrCodeIConMobile
                      style={{
                        cursor: 'pointer',
                      }}
                      ml={{ sm: '6px', lg: '24px' }}
                      w={{ sm: '24px', lg: '32px' }}
                      h={{ sm: '24px', lg: '32px' }}
                      onClick={() => {
                        setShowQRCode(true);
                        onOpen();
                      }}
                    />
                    <Button
                      size='sm'
                      colorScheme='blue'
                      ml={{ lg: 4, sm: 1 }}
                      fontSize='base'
                      px={5}
                      py={2}
                      onClick={() => applyVoucher()}
                    >
                      Apply Code
                    </Button>
                  </Flex>
                </FormControl>
              </Stack>
              {listRatePlan.length > 0 && (
                <Button
                  maxW='120px'
                  size='sm'
                  onClick={() => resetRatePlan()}
                  colorScheme='blue'
                >
                  Reset Rate Plan
                </Button>
              )}
            </Stack>
          </CardBody>
        </Card>

        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} mt='20px'>
          <CardBody>
            <Table variant='simple' color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  <Th borderColor={borderColor} color='gray.400'>
                    Channel
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Unit
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Program Applied
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Seat
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Floor
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Zone
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Official Price
                  </Th>
                  <Th borderColor={borderColor} color='gray.400'>
                    Amount
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {bookingInfo &&
                  bookingInfo?.calculator?.map((row, index, arr) => {
                    return (
                      <React.Fragment key={index}>
                        <TablesTableRow
                          row={row}
                          paddingY={'0px'}
                          isLast={index === arr.length - 1 ? true : false}
                        />
                      </React.Fragment>
                    );
                  })}
              </Tbody>
            </Table>
          </CardBody>
          <Flex align='flex-end' justify='flex-end' mt={3}>
            <Flex minW={{ xl: '480px' }} direction='column'>
              <Flex align='center' justify='space-between'>
                <Text>Total Amount of this booking:</Text>
                <Text>{totalAmount.toLocaleString('en-US')} VND</Text>
              </Flex>
              <HSeparator my={2} />
              <Flex align='center' justify='space-between'>
                <Flex direction='column'>
                  <Text>Do you want to do a deposit?</Text>
                </Flex>
                <Flex direction='column'>
                  <Controller
                    name='deposit'
                    control={control}
                    defaultValue='no'
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup onChange={onChange} value={value}>
                        <Stack
                          direction='row'
                          spacing={2}
                          justifyContent='flex-end'
                        >
                          <Radio value='no'>No</Radio>
                          <Radio value='yes'>Yes</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                  <NumericFormat
                    customInput={Input}
                    thousandSeparator=','
                    fontSize='sm'
                    ms='4px'
                    size='sm'
                    defaultValue={0}
                    disabled={deposit === 'no'}
                    value={depositValue}
                    onValueChange={(values) =>
                      setDepositValue(values.floatValue)
                    }
                    textAlign='right'
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue < totalAmount;
                    }}
                  />
                </Flex>
              </Flex>
              <HSeparator my={2} />
              <Flex align='center' justify='space-between'>
                <Text>Deposit:</Text>
                {deposit === 'yes' && (
                  <Text>{depositValue.toLocaleString('en-US')} VND</Text>
                )}
              </Flex>
              <HSeparator my={2} />
              <Flex align='center' justify='space-between'>
                <Text>Remaining:</Text>
                <Text>{totalRemain.toLocaleString('en-US')} VND</Text>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card p='16px' my={{ sm: '24px', xl: '25px' }} mt={{ lg: '45px' }}>
          <CardHeader p='12px 5px'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              Preview & Payment
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Grid
              templateColumns={{
                lg: '30% auto',
                md: 'repeat(1, 1fr)',
              }}
              gap='30px'
            >
              <Flex direction='column' gridColumn={1}>
                <Flex align='center' mb='18px'>
                  <Text
                    fontSize='md'
                    color={textColor}
                    fontWeight='bold'
                    me='10px'
                    minW={{ base: '100px', lg: '150px' }}
                  >
                    Total:
                  </Text>
                  <Text fontSize='base' fontWeight='bold'>
                    {deposit === 'no'
                      ? totalAmount.toLocaleString('en-US')
                      : depositValue.toLocaleString('en-US')}{' '}
                    VND
                  </Text>
                </Flex>
                <Flex mb='18px' align='center'>
                  <Text
                    fontSize='md'
                    color={textColor}
                    fontWeight='bold'
                    me='10px'
                    minW={{ base: '100px', lg: '150px' }}
                  >
                    Payment Status:
                  </Text>
                  <Select
                    variant='main'
                    fontSize='xs'
                    size='sm'
                    name='payment_status'
                    {...register('payment_status')}
                  >
                    {listPaymentStatus.map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        disabled={
                          (parseInt(item.id) === 2 &&
                            unit?.is_liability !== 1) ||
                          (parseInt(item.id) === 3 && deposit === 'yes')
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                {(parseInt(paymentStatus) === 3 || deposit === 'yes') && (
                  <>
                    <Flex mb={4} width='100%' align='center'>
                      <Text
                        fontSize='md'
                        color={textColor}
                        fontWeight='bold'
                        me='10px'
                        minW={{ base: '100px', lg: '150px' }}
                      >
                        Validity:
                      </Text>
                      <Box
                        w='100%'
                        sx={{
                          '& .chakra-input': {
                            width: '100% !important',
                          },
                        }}
                      >
                        {bookingInfo && (
                          <Controller
                            name='validity'
                            control={control}
                            render={({ field }) => (
                              <ReactDatePicker
                                {...field}
                                autoComplete={'off'}
                                calendarStartDay={1}
                                filterTime={filterPassedTime}
                                customInput={<Input />}
                                showTimeSelect
                                selected={field?.value}
                                dateFormat='dd/MM/yyyy hh:mm aa'
                                timeFormat='hh:mm aa'
                                className='booking-date-range-search'
                                closeOnScroll={false}
                                filterDate={filterDate}
                                onChange={(value) => {
                                  field.onChange(value);
                                }}
                                // required={isRequiredValidity()}
                              />
                            )}
                          />
                        )}
                      </Box>
                    </Flex>
                    <Flex mb={4} width='100%' align='center'>
                      <Text color='red' fontSize='sm'>
                        {errors.validity?.message}
                      </Text>
                    </Flex>
                  </>
                )}
                {paymentMethod.length > 0 && (
                  <Flex mt={2} mb={4} width='100%' align='center'>
                    <Text
                      fontSize='md'
                      color={textColor}
                      fontWeight='bold'
                      me='10px'
                      minW={{ base: '100px', lg: '150px' }}
                    >
                      Payment Method:
                    </Text>
                    <Select
                      variant='main'
                      fontSize='xs'
                      size='sm'
                      defaultValue={0}
                      name='payment_method'
                      {...register('payment_method')}
                    >
                      {paymentMethod.map((i) => (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                )}
                <Flex align='center'>
                  <Text
                    fontSize='md'
                    color={textColor}
                    fontWeight='bold'
                    me='10px'
                    minW={{ base: '100px', lg: '150px' }}
                  >
                    Booking Notes:
                  </Text>
                  <Textarea rows={4} {...register('notes')} name='notes' />
                </Flex>
              </Flex>
              <Flex justifyContent='space-around'>
                <Grid
                  templateColumns={{
                    sm: '1fr',
                    lg: 'repeat(2, auto)',
                  }}
                  gap={4}
                >
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Show Name:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.show?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Venue:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.venue?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Show Date:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {moment(bookingInfo?.show_date).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Show Time:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.show_time.slice(0, -3)}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Address:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.venue?.address}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Seat No.:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.seats
                        ?.map((seat) => seat.seat_number)
                        .join(', ')}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Adult:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.adult}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Child (5 - 12 ages):
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.child}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Zone:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {bookingInfo?.seats[0]?.zone?.name}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={2}>
                    <Text
                      fontSize='base'
                      color='#718096'
                      minW={{ base: '100px', lg: '110px' }}
                    >
                      Floor:
                    </Text>
                    <Text fontWeight='bold' fontSize='base'>
                      {showTextFloor(bookingInfo?.seats[0]?.floor)}
                    </Text>
                  </Flex>
                </Grid>
              </Flex>
            </Grid>
            <Flex align='center' justify='center' gap={4} mt={4}>
              <Button colorScheme='blue' mr={3} type='submit'>
                Proceed
              </Button>
              <Button
                onClick={async () => {
                  await bookingActions.cancelBooking();
                  history.push('/admin/booking/create');
                }}
              >
                Cancel Booking
              </Button>
            </Flex>
          </CardBody>
        </Card>

        <Portal>
          <Box>
            <CountDown expTime={selectedShow.booking.expire_at} />
          </Box>
        </Portal>
      </form>
      {showQRCode && (
        <>
          <Modal
            isOpen={isOpen}
            onClose={() => {
              onClose();
              disableCamera();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Scan QR</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <QrScanner
                  onDecode={(result) => scanQRCode(result)}
                  onError={(error) => alert(error?.message)}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
export default BookingPayment;
