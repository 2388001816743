import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
  Input,
  Text,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalBody,
  ModalHeader,
  Box,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { QrCodeIConMobile, QrCodeIConDeskTop } from 'components/Icons/Icons';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useBookingActions } from 'hooks/useBookingActions';

function SearchInput({ isModal = false }) {
  const [searchValue, setSearchValue] = useState('');
  const [bookingsSearch, setBookingsSearch] = useState([]);
  const [notHaveValue, setNotHaveValue] = useState('');
  const {
    isOpen: isOpenScanQR,
    onOpen: onOpenScanQR,
    onClose: onCloseScanQR,
  } = useDisclosure();
  const bookingActions = useBookingActions();

  const scanQRCode = (val) => {
    try {
      setSearchValue(val);
      search(val);
    } catch (error) {
      console.warn(error);
      setBookingsSearch([]);
    }
    onCloseScanQR();
  };
  const debouncedSearch = debounce((query) => {
    search(query);
  }, 500);
  useEffect(() => {
    if (searchValue) {
      setNotHaveValue('');
      setBookingsSearch([]);
      debouncedSearch(searchValue);
    }
    return debouncedSearch.cancel;
  }, [searchValue]);
  async function search(val) {
    const {
      data: { booking: bookingsSearch },
    } = await bookingActions.quickSearchBooking(val, 0, 10);
    if (bookingsSearch) {
      setBookingsSearch(bookingsSearch);
      if (bookingsSearch.length === 1) {
        history.push(`/admin/booking/create/detail/${bookingsSearch[0].id}`);
        setBookingsSearch([]);
        handleClearSearch();
      }
    } else {
      setNotHaveValue('No Value');
    }
  }
  function handleClearSearch() {
    setSearchValue('');
    setNotHaveValue('');
  }
  return (
    <>
      <Box pos='relative'>
        <InputGroup
          maxW='600px'
          className={!isModal ? 'input-group' : ''}
          sx={{
            '&.input-group .input': {
              // color: 'white',

              '&::placeholder': {
                // color: 'white',
              },

              '&:focus': {
                borderColor: 'white',
              },
            },

            '&.input-group .chakra-icon': {
              // color: 'white',
            },
          }}
        >
          <InputLeftElement pointerEvents='none'>
            <SearchIcon color='gray.700' />
          </InputLeftElement>
          <Input
            type='text'
            placeholder='Code/Special Request'
            value={searchValue}
            borderRadius={10}
            className='input'
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <InputRightElement right={3}>
            {searchValue && (
              <CloseIcon
                cursor='pointer'
                onClick={handleClearSearch}
                w='12px'
                h='12px'
              />
            )}
            {isModal ? (
              <QrCodeIConMobile
                style={{
                  cursor: 'pointer',
                  borderLeft: '1px solid var(--chakra-colors-gray-700)',
                }}
                pl='0'
                ml='10px'
                w={{ sm: '24px', lg: '48px' }}
                h={{ sm: '24px', lg: '28px' }}
                onClick={() => {
                  onOpenScanQR();
                }}
                sx={{ 
                  '& path': {
                    fill: 'gray.700'
                  }
                }}
              />
            ) : (
              <QrCodeIConDeskTop
                style={{
                  cursor: 'pointer',
                  borderLeft: '1px solid var(--chakra-colors-gray-700)',
                }}
                pl='0'
                ml='10px'
                w={{ sm: '24px', lg: '48px' }}
                h={{ sm: '24px', lg: '28px' }}
                sx={{ 
                  '& path': {
                    fill: 'gray.700'
                  }
                }}
              />
            )}
          </InputRightElement>
        </InputGroup>

        {searchValue && bookingsSearch.length > 0 ? (
          !isModal ? (
            <Box
              sx={{
                position: 'absolute',
                zIndex: '101',
                backgroundColor: 'white',
                width: '100%',
                marginTop: '5px',
                padding: '0',
                borderRadius: '8px',
                boxShadow: 'var(--chakra-shadows-md)',
                overflowX: 'hidden',
                overflowY: 'auto',
                maxH: '340px',
              }}
            >
              {bookingsSearch.map((i) => (
                <Flex
                  borderBottom='1px solid var(--chakra-colors-gray-700)'
                  p={'8px 16px'}
                  cursor='pointer'
                  direction='column'
                  gap={0}
                  key={i.code}
                  onClick={() => {
                    history.push(`/admin/booking/create/detail/${i.id}`);
                    setSearchValue('');
                  }}
                  _hover={{
                    '& .title': {
                      color: 'var(--chakra-colors-blue-500)',
                    },
                  }}
                >
                  <Text
                    className='title'
                    fontSize={12}
                    fontWeight={'bold'}
                    mb='0'
                  >
                    {i.code} - {i.zone.name && `${i.zone.name} -`} {i.unit.name}
                  </Text>
                  <Text fontSize={11} fontWeight={'300'} mb='0'>
                    Quanlity: {parseInt(i.adult) + parseInt(i.child)} - Booker:{' '}
                    {i.booker_name} - Audience: {i.guest_name}
                  </Text>
                  <Text fontSize={11} fontWeight={'300'} mb='0'>
                    Special Request:{' '}
                    <div dangerouslySetInnerHTML={{ __html: i.notes }}></div>
                  </Text>
                </Flex>
              ))}
            </Box>
          ) : (
            <Flex
              border={'1px solid var(--chakra-colors-gray-700)'}
              direction='column'
              w={'100%'}
              gap='20px'
            >
              <Flex direction='column' maxH='55vh' overflowY='auto'>
                {bookingsSearch.map((i) => (
                  <Flex
                    borderBottom='1px solid var(--chakra-colors-gray-700)'
                    p={'8px 22px'}
                    cursor='pointer'
                    direction='column'
                    gap={2}
                    _hover={{
                      '& .title': {
                        color: 'var(--chakra-colors-blue-500)',
                      },
                    }}
                    key={i.code}
                    onClick={() => {
                      history.push(`/admin/booking/create/detail/${i.id}`);
                      setSearchValue('');
                    }}
                  >
                    <Text fontSize={12} fontWeight={'bold'} className='title'>
                      {i.code} - {i.zone.name && `${i.zone.name} -`}{' '}
                      {i.unit.name}
                    </Text>
                    <Text fontSize={11} fontWeight={'300'}>
                      Quanlity: {parseInt(i.adult) + parseInt(i.child)} -
                      Booker: {i.booker_name} - Audience: {i.guest_name}
                    </Text>
                    <Text fontSize={11} fontWeight={'300'}>
                      Special Request:{' '}
                      <div dangerouslySetInnerHTML={{ __html: i.notes }}></div>
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )
        ) : !isModal ? (
          notHaveValue && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: '101',
                backgroundColor: 'white',
                width: '100%',
                marginTop: '5px',
                padding: '8px 16px',
                borderRadius: '8px',
                boxShadow: 'var(--chakra-shadows-md)',
                fontSize: 'var(--chakra-fontSizes-base)',
              }}
            >
              {notHaveValue}
            </Box>
          )
        ) : (
          <Box>{notHaveValue}</Box>
        )}
      </Box>
      <Modal isOpen={isOpenScanQR} onClose={onCloseScanQR}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scan QR</ModalHeader>
          <ModalBody>
            <QrScanner
              onDecode={(result) => scanQRCode(result)}
              onError={(error) => alert(error?.message)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
export default SearchInput;
