import {
  Button,
  useColorModeValue,
  Text,
  Flex,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { useBookingActions } from 'hooks/useBookingActions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function CountDown(props) {
  const { expTime, ...rest } = props;
  let bgButton = useColorModeValue('white', 'gray.600');
  const bookingActions = useBookingActions();
  const [timeLeft, setTimeLeft] = useState({
    minutes: '',
    seconds: '',
  });
  const history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      const duration = moment.duration(moment(expTime).diff(moment()));
      setTimeLeft({
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(async () => {
    if (parseInt(timeLeft.seconds) < 0) {
      history.push('/admin/booking/create');
      await bookingActions.cancelBooking();
    }
  }, [timeLeft]);
  return (
    <>
      <Button
        h={{ base: '40px', lg: '60px' }}
        w={{ base: '40px', lg: '60px' }}
        bg={bgButton}
        position='fixed'
        left={document.documentElement.dir === 'rtl' ? '35px' : 'auto'}
        right={
          document.documentElement.dir === 'rtl'
            ? 'auto'
            : { base: '70px', lg: '105px' }
        }
        bottom='30px'
        borderRadius='50px'
        boxShadow='0 2px 12px 0 rgb(0 0 0 / 16%)'
        display='flex'
      >
        <CircularProgress
          value={timeLeft.minutes}
          max={14}
          size={{ base: '46px', lg: '70px' }}
          thickness='4px'
          color='green'
          sx={{
            '@media (max-width: 767px)': {
              fontSize: '46px',

              '& .label-countdown': {
                display: 'none',
              },
            },
          }}
        >
          <CircularProgressLabel fontSize='sm'>
            <Flex direction='column'>
              <Text fontSize='23px' color={'green.400'}>
                {timeLeft.minutes}
              </Text>
              <Text className='label-countdown' fontSize='10px'>
                Minutes
              </Text>
            </Flex>
          </CircularProgressLabel>
        </CircularProgress>
      </Button>
      <Button
        h={{ base: '40px', lg: '60px' }}
        w={{ base: '40px', lg: '60px' }}
        bg={bgButton}
        position='fixed'
        variant='no-effects'
        left={document.documentElement.dir === 'rtl' ? '35px' : 'auto'}
        right={
          document.documentElement.dir === 'rtl'
            ? 'auto'
            : { base: '24px', lg: '35px' }
        }
        bottom='30px'
        borderRadius='50px'
        boxShadow='0 2px 12px 0 rgb(0 0 0 / 16%)'
      >
        <CircularProgress
          value={timeLeft.seconds}
          max={60}
          size={{ base: '46px', lg: '70px' }}
          thickness='4px'
          color='green'
          sx={{
            '@media (max-width: 767px)': {
              fontSize: '46px',

              '& .label-countdown': {
                display: 'none',
              },
            },
          }}
        >
          <CircularProgressLabel fontSize='sm'>
            <Flex direction='column'>
              <Text fontSize='23px' color={'green.400'}>
                {timeLeft.seconds}
              </Text>
              <Text className='label-countdown' fontSize='10px'>
                Seconds
              </Text>
            </Flex>
          </CircularProgressLabel>
        </CircularProgress>
      </Button>
    </>
  );
}
