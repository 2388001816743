import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  useColorModeValue,
  Skeleton,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HSeparator } from "components/Separator/Separator";
import { useBookingActions } from "hooks/useBookingActions";

export default function ComboCard({ item, isSelected, onSelect, bookingInfo }) {
  const textColor = useColorModeValue("gray.700", "white");
  const [numberCombo, setNumberCombo] = useState();
  const bookingActions = useBookingActions();
  const min = item.rateplan_configs[0]?.min;
  let max = item.rateplan_configs[0]?.max;
  if (bookingInfo?.adult + bookingInfo?.child < max) {
    max = bookingInfo?.adult + bookingInfo?.child;
  }
  const options = [];
  for (let i = min; i <= max; i++) {
    options.push({ value: i, label: i.toString() });
  }

  useEffect(() => {
    if (numberCombo) {
      onSelect({
        id: item.rateplan_configs[0].id,
        quantity: parseInt(numberCombo),
        updateQuantity: true,
      });
    }
  }, [numberCombo]);
  const handleDropdownChange = (e) => {
    e.stopPropagation();
    setNumberCombo(e.target.value);
  };
  return (
    <Card
      minH="100%"
      alignSelf="flex-start"
      border={isSelected && "2px solid var(--chakra-colors-blue-500)"}
      onClick={() => {
        !isSelected &&
          onSelect({ id: item.rateplan_configs[0].id, quantity: min });
      }}
    >
      <CardHeader>
        <Flex justify="space-between" w="100%">
          {isSelected && (
            <CheckCircleIcon
              boxSize={5}
              color="var(--chakra-colors-blue-500)"
              position="absolute"
              top="8px"
              right="8px"
            />
          )}

          <Text fontSize="md" color={textColor} fontWeight="bold" mb="8px">
            {item.name}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        {item.rateplan_configs[0]?.adult_amount && (
          <Flex>
            Price:{" "}
            {item?.total_price && item.total_price.toLocaleString("en-US")} VND
          </Flex>
        )}
        <Flex>
          <Flex>Min: {item.rateplan_configs[0].min}</Flex>
          <Flex ml={3}>Max: {item.rateplan_configs[0].max} </Flex>
        </Flex>
        <HSeparator mb={4} mt={2} />
        {isSelected && (
          <Select width="50%" onChange={handleDropdownChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </CardBody>
    </Card>
  );
}
